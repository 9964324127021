<template>
	<frp-card v-if="isInitialized"
			  :title="$t('titles.correspondenceInsideFrp')"
			  class="pb-4">
		<template #content>
			<bar-card-content-layout class="pt-0">
				<v-card elevation="0"
						:class="{'bar-messenger-card--fullscreen': isMessengerFullscreen}"
						class="bar-messenger-card d-flex flex-column justify-end pa-4">
					<div class="bar-messenger-items-wrapper pr-14"
						 :class="{'bar-messenger-items-wrapper--fullscreen': isMessengerFullscreen, 'bar-messenger-items-wrapper--answer': messengerMode === MessengerModeType.ANSWER, 'justify-center': !isMessengerFullscreen && !groupedSortedMessages.length}"
						 ref="bar-messenger"
						 @scroll="showScrollItem">
						<template v-if="isMessagesFetching">
							<div v-for="(item, i) in 3" :key="i" class="bar-message-item flex-column mt-3">
								<div class="d-flex flex-column">
									<div class="d-flex align-center justify-space-between">
										<div class="d-flex align-center">
											<frp-text-loader class="mr-3" width="120" height="18"/>
											<frp-text-loader width="35" height="18"/>
										</div>
										<frp-text-loader width="20" height="18"/>
									</div>
									<v-skeleton-loader height="45" type="image" class="mt-1 rounded-md"/>
								</div>
							</div>
						</template>
						
						<template v-else-if="!groupedSortedMessages.length">
							<div class="d-flex flex-column">
								<span class="text-center text-body-2 grey--text">{{ $t("content.noMessages") }}</span>
							</div>
						</template>
						
						<template v-else>
							<template v-for="(group, i) in groupedSortedMessages">
								<div class="d-flex flex-column">
									<div class="bar-messenger-items-date mt-3">
										<div class="bar-messenger-items-date__previous-line"></div>
										<span class="text-body-2 grey--text lighten-3 px-2"
											  style="font-size: 12px !important; white-space: nowrap;">
											{{ group.key }}
										</span>
										<div class="bar-messenger-items-date__next-line"></div>
									</div>
									<bar-bank-account-application-message-item v-for="(message, j) in group.value"
																			   :key="message.id"
																			   :message="message">
									</bar-bank-account-application-message-item>
								</div>
							</template>
						</template>
					</div>
					
					<v-fade-transition>
						<frp-btn v-show="isScrollToBottomButtonVisible"
								 small
								 :color="colors.blue.base"
								 class="bar-messenger-scroll-button"
								 :class="{'bar-messenger-scroll-button--small-display': $vuetify.breakpoint.mdAndDown && !isMessengerFullscreen}"
								 @click="scrollToBottom"
								 icon>
							<v-icon size="50" :color="colors.blue.base">mdi-arrow-down-bold-circle-outline</v-icon>
						</frp-btn>
					</v-fade-transition>
					
					<bar-bank-account-application-messenger-actions @update:scroll-to-bottom="scrollToBottom"
																	@message:sent="handleMessageSent"
																	:menuTop="menuTop"
					/>
				</v-card>
			</bar-card-content-layout>
		</template>
		<template #actions>
			<frp-btn small
					 height="30"
					 style="margin-left: 4px !important; margin-right: -10px !important;"
					 :color="colors.blue.base"
					 class="mr-1"
					 icon
					 @click="changeMessengerWindowSize">
				<frp-icon src="ico_zoom" :color="colors.blue.darken4"></frp-icon>
			</frp-btn>
		</template>
	</frp-card>
	<bar-bank-account-application-messenger-loader v-else/>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpCard from "@/components/cards/FrpCard.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import BarCardContentLayout from "@/components/layouts/BarCardContentLayout.vue";
import FrpTextLoader from "@/components/loaders/common/FrpTextLoader.vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import formMixin from "@/mixins/formMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { RouteNames } from "@/router/bar/routes";
import { namespace } from "@/store/bar/modules/bankAccountApplication/modules/messenger";
import { getterTypes, mutationTypes, actionTypes } from "@/store/bar/modules/bankAccountApplication/modules/messenger/types";
import { MessengerModeType } from "@/store/bar/modules/bankAccountApplication/modules/messenger/types/messengerModeType";
import BarBankAccountApplicationMessageItem
	from "@/views/bar/bankAccountApplication/sections/modules/messenger/BarBankAccountApplicationMessageItem.vue";
import BarBankAccountApplicationMessengerActions
	from "@/views/bar/bankAccountApplication/sections/modules/messenger/BarBankAccountApplicationMessengerActions.vue";
import BarBankAccountApplicationMessengerLoader
	from "@/views/bar/bankAccountApplication/sections/modules/messenger/BarBankAccountApplicationMessengerLoader.vue";
import { orderBy, groupBy, map } from "lodash";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [formMixin, colorsMixin, authorizationMixin],
	props: {
		menuTop: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			namespace,
			RouteNames,
			MessengerModeType,
			isScrollToBottomButtonVisible: false
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			isMessagesFetching: state => state.isMessagesFetching,
			messages: state => state.messages,
			isMessengerFullscreen: state => state.isMessengerFullscreen,
			messengerMode: state => state.messengerMode
		}),
		...mapGetters({}),
		groupedSortedMessages() {
			const sortedMessages = this.sortItemsByDate(this.messages, "createdAt");
			
			return map(groupBy(sortedMessages, "groupedDate"), (value, key) => {
				return {
					key,
					value: this.sortItemsByDate(value, "createdAt", "asc")
				};
			});
		}
	},
	methods: {
		...mapMutations({
			setIsMessengerFullscreen: mutationTypes.SET_IS_MESSENGER_FULLSCREEN
		}),
		...mapActions({}),
		sortItemsByDate(items, keyName, order = "desc") {
			return orderBy(items, [(x) => new Date(x[keyName])], [order]);
		},
		changeMessengerWindowSize() {
			this.setIsMessengerFullscreen(!this.isMessengerFullscreen);
		},
		scrollToBottom() {
			const messenger = this.$refs["bar-messenger"];
			messenger.scrollTop = messenger.scrollHeight;
		},
		showScrollItem(event) {
			this.isScrollToBottomButtonVisible = event.currentTarget.scrollTop < -100;
		},
		handleMessageSent() {
			this.$emit("message:sent");
		}
	},
	components: {
		FrpTextLoader,
		BarBankAccountApplicationMessengerLoader,
		BarBankAccountApplicationMessengerActions,
		BarBankAccountApplicationMessageItem,
		FrpIcon,
		FrpBtn,
		BarCardContentLayout,
		FrpCard
	}
};
</script>

<style scoped lang="scss">
.bar-messenger-card {
	position: relative;
	border: 1px solid #939393;
	border-radius: 4px;

	&--fullscreen {
		height: calc(100vh - 280px);
	}
}

.bar-messenger-items-wrapper {
	display: flex;
	flex-direction: column-reverse;
	scroll-behavior: smooth;

	min-height: 235px;
	max-height: 310px;
	overflow-y: auto;
	overflow-x: hidden;

	&--answer {
		max-height: 221px;
	}

	&--fullscreen {
		height: auto;
		max-height: max-content;
	}

	&::-webkit-scrollbar {
		width: 6px !important;
	}

	&::-webkit-scrollbar-track {
		background-color: var(--v-blue-base) !important;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--v-blue-lighten4) !important;
	}
}

.bar-messenger-items-date {
	position: relative;
	display: flex;
	align-items: center;

	&__previous-line, &__next-line {
		height: 0.5px;
		background: var(--v-grey-lighten3);
		width: 100%;
	}
}

.bar-messenger-scroll-button {
	position: absolute;
	right: 33px;
	bottom: 70px;
	z-index: 1;

	&--small-display {
		bottom: 160px;
	}
}
</style>
