import IPageState from "@/store/shared/base/types/pageState";
import ApiApplicationStatus from "@/api/bar/types/dictionaries/apiApplicationStatus";
import ApiElectronicDocumentStatus from "@/api/bar/types/dictionaries/apiElectronicDocumentStatus";
import ApiBankAccountType from "@/api/bar/types/dictionaries/apiBankAccountType";
import FormState from "@/store/shared/form/models/formState";
import { ApiCurrency } from "@/api/bar/types/dictionaries/apiCurrency";
import { BankAccountApplicationInfo, BankAccountApplicationInfoHelper } from "@/store/bar/types/bankAccountApplicationInfo";
import { BankInfo } from "@/store/bar/types/bankInfo";
import { BankBranchInfo } from "@/store/bar/types/bankBranchInfo";
import { ApiStaffEmployee } from "@/api/bar/types/apiStaffEmployee";
import { StatusHistoryItem } from "@/store/bar/types/statusHistoryItem";
import { ApiBankAccountApplicationInfoPersisted } from "@/api/bar/types/bankAccountApplicationInfo/apiBankAccountApplicationInfoPersisted";
import ApiPaperDocumentStatus from "@/api/bar/types/dictionaries/apiPaperDocumentStatus";
import ApiApplicationBankAccountUsageType from "@/api/bar/types/dictionaries/apiApplicationBankAccountUsageType";
import { ApiAccountUsageItem } from "@/api/bar/types/apiAccountUsageItem";
import ApiSigners from "@/api/bar/types/dictionaries/apiSigners";
import { ApiBankSettings } from "@/api/bar/types/apiBankSettings";
import { ApiExistingApplication } from "@/api/bar/types/apiExistingApplicationsPersisted";

export default class BankAccountApplicationState implements IPageState {
	constructor(
		public form: FormState,
		public snapshot: object,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isDictionariesLoading: boolean = false,
		public isFetchBankAccountApplicationLoading: boolean = false,
		public isBankAccountApplicationSaving: boolean = false,
		public isFetchStatusHistoryLoading: boolean = false,
		public isBankAccountApplicationBankFormValid: boolean = false,
		public isBankAccountApplicationBankFormDisabled: boolean = false,
		public isBankAccountApplicationAccountsFormValid: boolean = false,
		public isBankAccountApplicationAccountsFormDisabled: boolean = false,
		public signStatuses: ApiApplicationStatus[] = [],
		public bankAccountTypes: ApiBankAccountType[] = [],
		public electronicDocumentStatuses: ApiElectronicDocumentStatus[] = [],
		public paperDocumentStatuses: ApiPaperDocumentStatus[] = [],
		public applicationStatuses: ApiApplicationStatus[] = [],
		public currencies: ApiCurrency[] = [],
		public staffEmployees: ApiStaffEmployee[] = [],
		public frpEmployees: ApiStaffEmployee[] = [],
		public statusHistoryItems: StatusHistoryItem[] = [],
		public banks: BankInfo[] = [],
		public bankBranches: BankBranchInfo[] = [],
		public LKApplications: ApiBankAccountApplicationInfoPersisted[] = [],
		public bik: string = "",
		public correspondentAccount: string = "",
		public editableItem: BankAccountApplicationInfo = BankAccountApplicationInfoHelper.getEmpty(),
		public isLegalDepartmentRequestLoading: boolean = false,
		public isFetchSuggestedBankAndBankBranchByBikLoading: boolean = false,
		public accountUsages: ApiAccountUsageItem[] = [],
		public applicationBankAccountUsageTypes: ApiApplicationBankAccountUsageType[] = [],
		public isReturningToDsp: boolean = false,
		public isReturningToUfkp: boolean = false,
		public isStatusUpdatingToConnect: boolean = false,
		public isBankApplicationTemplateDownloading: boolean = false,
		public signers: ApiSigners[] = [],
		public bankSettings: ApiBankSettings = {},
		public existingApplications: ApiExistingApplication[] = []
	)
	{
	}
}
