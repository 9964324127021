<template>
	<bar-details-item>
		<v-row class="py-2">
			<v-col cols="auto" class="d-flex align-center">
				<frp-autocomplete v-if="agreement.isNewItem"
								  v-model="loanApplicationNumber"
								  ref="loanApplicationNumber"
								  :errors="loanApplicationNumberErrors"
								  :min-query-length="MIN_PROJECTS_QUERY_LENGTH"
								  :items="agreement.suggestedProjects"
								  @update:search-input="getSuggestedProjects"
								  item-value="loanApplicationNumber"
								  item-text="name"
								  color="blue"
								  class="bar-field"
								  width="230px"
								  hide-details
								  required
								  :disabled="isAgreementItemDisabled"
								  :loading="agreement.isFetchProjectsLoading || agreement.isNumberUniqueCheckInProgress"
								  :placeholder="$t('fields.project.placeholder')">
				</frp-autocomplete>
				
				<template v-else>
					<div style="width: 210px">
						<frp-text-btn v-if="projectLink && agreement.number"
									  :href="projectLink"
									  class="pa-0 bar-text-btn bar-project-link font-weight-bold"
									  style="min-width: fit-content;"
									  :text="agreement.number"
									  color="primary"
									  target="_blank">
						</frp-text-btn>
						<span v-else class="text-subtitle-2 primary--text">{{ agreement.number || "" }}</span>
					</div>
				</template>
			</v-col>
			
			<v-spacer></v-spacer>
			
			<v-col cols="auto">
				<div class="d-flex align-center">
					<frp-date-field v-model="agreement.startDate"
									hide-details
									class="bar-field"
									width="210px"
									:tooltip="$t('fields.startDate.tooltip')"
									bar-date-field
									required
									:disabled="!can(Permissions.BAR_ACCOUNT_UPDATE) || isAgreementItemDisabled"
									:placeholder="$t('fields.startDate.placeholder')">
					</frp-date-field>
					
					<frp-autocomplete v-model="accountType"
									  :items="bankAccountTypes"
									  item-text="title"
									  item-value="code"
									  color="blue"
									  class="bar-field ml-3"
									  hide-details
									  width="210px"
									  required
									  :disabled="!can(Permissions.BAR_ACCOUNT_UPDATE) || isAgreementItemDisabled"
									  :placeholder="$t('fields.bankAccountType.placeholder')">
					</frp-autocomplete>
					
					<frp-btn v-if="agreement.isNewItem && accountAgreements.length > 1"
							 small
							 color="blue"
							 class="ml-2"
							 icon
							 height="32"
							 :disabled="isBankAccountSaving"
							 @click="deleteAccountAgreementsItem(index)">
						<frp-icon src="ico_delete" :color="colors.primary.darken1"></frp-icon>
					</frp-btn>
					
					<frp-btn v-if="!agreement.isNewItem && isBarUserAdministrator && editableItem.accountAgreements.filter(item => !item.isNewItem).length > 1"
							 small
							 color="blue"
							 class="ml-2"
							 icon
							 height="32"
							 :disabled="isDisconnectingAccountAgreementsItemInProgress"
							 @click="isDisconnectAgreementDialogOpened = true">
						<frp-icon src="ico_delete" :color="colors.primary.darken1"></frp-icon>
					</frp-btn>
					<frp-btn v-if="!agreement.isNewItem"
							 small
							 @click="handleOpenAgreementTypeHistoryDialogAndFetchHistory"
							 class="ml-2"
							 color="blue"
							 height="32"
							 :disabled="isBankAccountAgreementTypesHistoryLoading && !isCurrentAgreementTypeHistoryLoading"
							 :loading="isCurrentAgreementTypeHistoryLoading"
							 icon>
						<frp-icon src="ico_history" :color="colors.blue.darken4"></frp-icon>
					</frp-btn>
				</div>
			</v-col>
		</v-row>
		
		<bar-bank-account-agreements-agreement-item-type-history-dialog
			v-model="isCurrentAgreementTypeHistoryDialogOpened"
			:loan-application-number="agreement.number.toString()"
			:counterparty-short-name="agreement.agreement?.counterparty.shortName"
			:agreement-type-history-items="currentAgreementTypeHistory">
		</bar-bank-account-agreements-agreement-item-type-history-dialog>
		<bar-bank-account-disconnect-agreements-item-dialog
			v-if="isDisconnectAgreementDialogOpened"
			v-model="isDisconnectAgreementDialogOpened"
			:agreement-id="agreement.number.toString()"
			:agreement-index="index"
			:bank-account-id="this.id">
		</bar-bank-account-disconnect-agreements-item-dialog>
	</bar-details-item>
</template>

<script>
import { BarController } from "@/api/bar";
import { ApiAccountAgreement } from "@/api/bar/types/accountAgreement/apiAccountAgreement";
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpTextBtn from "@/components/buttons/FrpTextBtn.vue";
import FrpCard from "@/components/cards/FrpCard.vue";
import BarDetailsGroup from "@/components/details/BarDetailsGroup.vue";
import BarDetailsItem from "@/components/details/BarDetailsItem.vue";
import FrpAutocomplete from "@/components/fields/FrpAutocomplete.vue";
import FrpDateField from "@/components/fields/FrpDateField.vue";
import FrpTextField from "@/components/fields/FrpTextField.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import { externalUrls } from "@/constants/bar/externalUrls";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import { RouteNames } from "@/router/bar/routes";
import AbortService from "@/services/abortService";
import BatchService from "@/services/batchService";
import { MIN_PROJECTS_QUERY_LENGTH } from "@/store/bar/constants";
import { getterTypes as barUserModuleGetterTypes } from "@/store/bar/modules/user/types";
import { BankAccountTypeEnum } from "@/store/bar/types/bankAccountTypeEnum";
import { BankAccountTypeHistoryMapper } from "@/store/bar/types/bankAccountTypeHistory";
import { SourceTypeEnum } from "@/store/bar/types/SourceTypeEnum";
import storeManager from "@/store/manager";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import { formatDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import { namespace } from "@/store/bar/modules/bankAccount";
import { actionTypes, getterTypes, mutationTypes } from "@/store/bar/modules/bankAccount/types";
import { maxLengths } from "@/utils/validation";
import BarBankAccountAgreementsAgreementItemTypeHistoryDialog
	from "@/views/bar/bankAccount/sections/agreements/agreementItem/agreementTypeHistoryDialog/BarBankAccountAgreementsAgreementItemTypeHistoryDialog.vue";
import BarBankAccountDisconnectAgreementsItemDialog
	from "@/views/bar/bankAccount/sections/agreements/agreementItem/disconnectAgreementsItemDialog/BarBankAccountDisconnectAgreementsItemDialog.vue";
import BarBankAccountAgreementsLoader from "@/views/bar/bankAccount/sections/agreements/BarBankAccountAgreementsLoader.vue";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);
const barUserModuleHelpers = createNamespacedHelpers(storeManager.bar.barUser.namespace);

const abortService = new AbortService();
const barController = new BarController(abortService);
const requestBatchService = new BatchService(({ interval: 500 }));

export default {
	mixins: [colorsMixin, authorizationMixin],
	props: {
		agreement: ApiAccountAgreement,
		index: Number
	},
	data() {
		return {
			RouteNames,
			SourceTypeEnum,
			formatDate,
			dateFormat,
			MIN_PROJECTS_QUERY_LENGTH,
			isCurrentAgreementTypeHistoryLoading: false,
			isCurrentAgreementTypeHistoryDialogOpened: false,
			isDisconnectAgreementDialogOpened: false,
			currentAgreementTypeHistory: []
		};
	},
	computed: {
		...mapState({
			isInitialized: state => state.isInitialized,
			applications: state => state.applications,
			id: state => state.editableItem.id,
			editableItem: state => state.editableItem,
			isBankAccountSaving: state => state.isBankAccountSaving,
			isBankAccountAgreementTypesHistoryLoading: state => state.isBankAccountAgreementTypesHistoryLoading,
			bankAccountNumber: state => state.editableItem.account.accountNumber,
			isBankAccountNumberUnique: state => state.isBankAccountNumberUnique,
			isBankAccountNumberUniqueCheckInProgress: state => state.isBankAccountNumberUniqueCheckInProgress,
			accountAgreements: state => state.editableItem.accountAgreements,
			bankAccountTypes: state => state.bankAccountTypes,
			isBankAccountHasPayments: state => state.isBankAccountHasPayments,
			isDisconnectingAccountAgreementsItemInProgress: state => state.editableItem.isDisconnectingAccountAgreementsItemInProgress
		}),
		...mapGetters({
		}),
		...barUserModuleHelpers.mapGetters({
			isBarUserAdministrator: barUserModuleGetterTypes.isBarUserAdministrator,
			isBarUserProjectFinancialControlDepartmentManager: barUserModuleGetterTypes.isBarUserProjectFinancialControlDepartmentManager
		}),
		loanApplicationNumberErrors() {
			return this.agreement.isNumberUnique ? [] : [`${this.$t("alerts.errors.agreementNumberAlreadyJointByBankAccountNumber")}`];
		},
		isAgreementItemDisabled() {
			return !this.isBankAccountNumberUnique
				|| this.isBankAccountSaving
				|| this.isBankAccountNumberUniqueCheckInProgress
				|| this.bankAccountNumber.length < maxLengths.bankAccountNumber
		},
		isCreateMode() {
			return !this.$route.params.id;
		},
		projectLink() {
			const accountAgreement = this.accountAgreements.find(x => x.number === this.agreement.number);
			
			let url = "";
			
			if(accountAgreement && accountAgreement?.agreement)
				url = `${externalUrls.lkProject}/${accountAgreement.agreement.entryId}`;
			
			return url;
		},
		loanApplicationNumber: {
			get() {
				return this.agreement.number;
			},
			set(value) {
				this.setLoanApplicationNumber({ index: this.index, value });
			}
		},
		accountType: {
			get() {
				if(this.agreement.accountType === BankAccountTypeEnum.UNKNOWN)
					return "";
				
				return this.agreement.accountType;
			},
			set(value) {
				this.setAccountType({ index: this.index, value });
			}
		},
		startDate: {
			get() {
				return this.agreement.startDate;
			},
			set(value) {
				this.setAccountStartDate(value);
			}
		}
	},
	methods: {
		...mapMutations({
			setIsBankAccountAgreementTypesHistoryLoading: mutationTypes.SET_IS_BANK_ACCOUNT_AGREEMENT_TYPES_HISTORY_LOADING,
			setIsNumberUnique: mutationTypes.SET_IS_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_NUMBER_UNIQUE,
			resetSuggestedProjects: mutationTypes.RESET_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_SUGGESTED_PROJECTS,
			setAccountType: mutationTypes.SET_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_ACCOUNT_TYPE,
			setAccountStartDate: mutationTypes.SET_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM_START_DATE,
			deleteAccountAgreementsItem: mutationTypes.DELETE_EDITABLE_ITEM_ACCOUNT_AGREEMENTS_ITEM,
			setIsDisconnectingAccountAgreementsItemInProgress: mutationTypes.SET_IS_DISCONNECTING_ACCOUNT_AGREEMENTS_ITEM_IN_PROGRESS
		}),
		...mapActions({
			tryFetchSuggestedProjects: actionTypes.tryFetchSuggestedProjects,
			setLoanApplicationNumber: actionTypes.setLoanApplicationNumber
		}),
		async getSuggestedProjects(query) {
			if(!query) this.resetSuggestedProjects(this.index);
			
			// мы проверяем, соответствует ли выбранный проект из query с тем, что есть в suggestedProjects. Если да, то не фетчим проекты заново.
			const project = this.agreement.suggestedProjects.find(x => x.name === query || String(x.loanApplicationNumber) === query);
			if(project && (project.loanApplicationNumber === this.agreement.number))
				return;
			
			if(query?.length && query.length >= MIN_PROJECTS_QUERY_LENGTH)
				requestBatchService.push(async () => await this.tryFetchSuggestedProjects({ index: this.index, query }));
		},
		async handleOpenAgreementTypeHistoryDialogAndFetchHistory() {
			this.isCurrentAgreementTypeHistoryLoading = true;
			this.setIsBankAccountAgreementTypesHistoryLoading(true);
			
			try {
				const apiHistory = await barController.getBankAccountAgreementTypeHistory(this.id, this.agreement.number.toString());
				
				this.currentAgreementTypeHistory = apiHistory.map(x => BankAccountTypeHistoryMapper.map(x));
				this.isCurrentAgreementTypeHistoryDialogOpened = true;
			} catch (error) {
				AlertHelper.handleGeneralRequestErrors(error);
				console.error(error);
			} finally {
				this.isCurrentAgreementTypeHistoryLoading = false;
				this.setIsBankAccountAgreementTypesHistoryLoading(false);
			}
		}
	},
	created() {
		abortService.initialize();
	},
	watch: {
		isCurrentAgreementTypeHistoryDialogOpened(value) {
			if(!value)
				this.currentAgreementTypeHistory = [];
		}
	},
	components: {
		BarBankAccountDisconnectAgreementsItemDialog,
		FrpDateField,
		BarBankAccountAgreementsAgreementItemTypeHistoryDialog,
		FrpTextField,
		FrpIcon,
		FrpAutocomplete,
		BarBankAccountAgreementsLoader,
		FrpBtn,
		FrpTextBtn,
		BarDetailsItem,
		BarDetailsGroup,
		FrpCard
	}
};
</script>

<style scoped>

</style>
