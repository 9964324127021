import { BankAccountApplicationButtonTypeEnum } from "@/store/bar/types/BankAccountApplicationButtonTypeEnum";
import { BankAccountApplicationStatusTypeEnum } from "@/store/bar/types/BankAccountApplicationStatusTypeEnum";

export const defaultBankAccountApplicationStatuses = [
	BankAccountApplicationStatusTypeEnum.IN_PROGRESS_FINANCIAL_CONTROLLER,
	BankAccountApplicationStatusTypeEnum.IN_PROGRESS_CURATORS_DEPARTMENT,
	BankAccountApplicationStatusTypeEnum.TO_CONNECT
];

export const allBankAccountApplicationStatuses = Object.values(BankAccountApplicationStatusTypeEnum);

export const buttonUnlockConditions: Record<BankAccountApplicationButtonTypeEnum, Record<string, (BankAccountApplicationStatusTypeEnum | string)[]>> = {
	[BankAccountApplicationButtonTypeEnum.APPLICATION_DELETE_BUTTON]: {
		isBarUserCuratorsDepartmentManager: [
			BankAccountApplicationStatusTypeEnum.INFORMATION_LETTER_UPLOADING,
			BankAccountApplicationStatusTypeEnum.IN_PROGRESS_CURATORS_DEPARTMENT,
			BankAccountApplicationStatusTypeEnum.IN_PROGRESS_FINANCIAL_CONTROLLER
		],
		default: []
	},
	[BankAccountApplicationButtonTypeEnum.EDIT_BUTTON]: {
		isBarUserBorrower: [BankAccountApplicationStatusTypeEnum.INFORMATION_LETTER_UPLOADING],
		isBarUserCuratorsDepartmentManager: [...defaultBankAccountApplicationStatuses, BankAccountApplicationStatusTypeEnum.COMPLETED],
		default: []
	},
	[BankAccountApplicationButtonTypeEnum.DSP_BUTTON]: {
		isBarUserProjectFinancialControlDepartmentManager: [
			BankAccountApplicationStatusTypeEnum.IN_PROGRESS_FINANCIAL_CONTROLLER,
			BankAccountApplicationStatusTypeEnum.TO_CONNECT],
		default: []
	},
	[BankAccountApplicationButtonTypeEnum.ON_CONNECT_BUTTON]: {
		isBarUserProjectFinancialControlDepartmentManager: [BankAccountApplicationStatusTypeEnum.IN_PROGRESS_FINANCIAL_CONTROLLER],
		default: []
	},
	[BankAccountApplicationButtonTypeEnum.UFKP_BUTTON]: {
		isBarUserProjectFinancialControlDepartmentManager: [BankAccountApplicationStatusTypeEnum.TO_CONNECT],
		default: []
	},
	[BankAccountApplicationButtonTypeEnum.COMMENT_BUTTON]: {
		isBarUserCuratorsDepartmentManager: [...defaultBankAccountApplicationStatuses, BankAccountApplicationStatusTypeEnum.COMPLETED],
		default: []
	},
	[BankAccountApplicationButtonTypeEnum.ELECTRONIC_STATUS_BUTTON]: {
		isBarUserCuratorsDepartmentManager: [...defaultBankAccountApplicationStatuses, BankAccountApplicationStatusTypeEnum.COMPLETED],
		default: []
	},
	[BankAccountApplicationButtonTypeEnum.PAPER_STATUS_BUTTON]: {
		isBarUserCuratorsDepartmentManager: [...defaultBankAccountApplicationStatuses, BankAccountApplicationStatusTypeEnum.COMPLETED],
		default: []
	},
	[BankAccountApplicationButtonTypeEnum.SEND_MESSAGE_BUTTON]: {
		isBarUserCuratorsDepartmentManager: [...defaultBankAccountApplicationStatuses, BankAccountApplicationStatusTypeEnum.COMPLETED],
		isBarUserProjectFinancialControlDepartmentManager: defaultBankAccountApplicationStatuses,
		isBarUserLawyerManager: defaultBankAccountApplicationStatuses,
		default: []
	},
	[BankAccountApplicationButtonTypeEnum.SEND_REQUEST_TO_LD_BUTTON]: {
		isBarUserCuratorsDepartmentManager: [...defaultBankAccountApplicationStatuses, BankAccountApplicationStatusTypeEnum.COMPLETED],
		default: []
	},
	[BankAccountApplicationButtonTypeEnum.LEGAL_DEPARTMENT_DECLINE_BUTTON]: {
		isBarUserLawyerManager: defaultBankAccountApplicationStatuses,
		default: []
	},
	[BankAccountApplicationButtonTypeEnum.LEGAL_DEPARTMENT_ACCEPT_BUTTON]: {
		isBarUserLawyerManager: defaultBankAccountApplicationStatuses,
		default: []
	},
	[BankAccountApplicationButtonTypeEnum.LEGAL_DEPARTMENT_ASSIGN_RESPONSIBLE_BUTTON]: {
		isBarUserLawyerManager: defaultBankAccountApplicationStatuses,
		default: []
	},
	[BankAccountApplicationButtonTypeEnum.LEGAL_DEPARTMENT_UPLOAD_DOCUMENT_BUTTON]: {
		isBarUserLawyerManager: defaultBankAccountApplicationStatuses,
		default: []
	},
	[BankAccountApplicationButtonTypeEnum.GENERATE_LETTER_BUTTON]: {
		isBarUserBorrower: [BankAccountApplicationStatusTypeEnum.INFORMATION_LETTER_UPLOADING],
		isBarUserCuratorsDepartmentManager: [BankAccountApplicationStatusTypeEnum.INFORMATION_LETTER_UPLOADING,
			...defaultBankAccountApplicationStatuses],
		default: []
	},
	[BankAccountApplicationButtonTypeEnum.UPLOAD_DOCUMENT_BUTTON]: {
		isBarUserBorrower: [
			BankAccountApplicationStatusTypeEnum.INFORMATION_LETTER_UPLOADING,
			BankAccountApplicationStatusTypeEnum.IN_PROGRESS_FINANCIAL_CONTROLLER,
			BankAccountApplicationStatusTypeEnum.IN_PROGRESS_CURATORS_DEPARTMENT,
			BankAccountApplicationStatusTypeEnum.TO_CONNECT,
			BankAccountApplicationStatusTypeEnum.COMPLETED
		],
		isBarUserCuratorsDepartmentManager: allBankAccountApplicationStatuses,
		default: []
	}
};
