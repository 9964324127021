<template>
	<frp-dialog :value="value"
				:title="$t('dialogs.withdrawVacationApplication.title')"
				max-width="455"
				persistent
				@update:value="$emit('update:value', $event)">
		<template #content="{ onIntersect }">
			<span>{{ $t("dialogs.withdrawVacationApplication.text") }}</span>
		</template>
		
		<template #footer>
			<frp-btn :disabled="isWithdrawing"
					 color="primary"
					 outlined
					 @click="$emit('update:value', false)">
				{{ $t("buttons.cancel") }}
			</frp-btn>
			<frp-btn elevation="0" color="blue" dark
					 :loading="isWithdrawing"
					 @click="handleWithdraw">
				{{ $t("buttons.withdraw") }}
			</frp-btn>
		</template>
	</frp-dialog>
</template>

<script>
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import storeManager from "@/store/manager";
import { RouteNames } from "Router/hr/routes";
import { actionTypes, getterTypes, mutationTypes, namespace } from "Store/hr/modules/vacationApplication/types";
import { createNamespacedHelpers } from "vuex";
import colorsMixin from "Mixins/colorsMixin";
import FrpBtn from "Components/buttons/FrpBtn";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean
	},
	data() {
		return {
			RouteNames,
			namespace
		};
	},
	computed: {
		...mapState({
			isWithdrawing: state => state.isVacationApplicationWithdrawing
		})
	},
	methods: {
		...mapActions({
			withdraw: actionTypes.withdrawVacationApplication
		}),
		async handleWithdraw() {
			await this.withdraw();
			this.$emit("update:value", false);
		}
	},
	components: {
		FrpDialog,
		FrpBtn
	}
};
</script>
