<template>
	<frp-confirm-dialog :description="$t('dialogs.disconnectAgreementsItem.description')"
						:submit-btn="$t('dialogs.disconnectAgreementsItem.submitBtn')"
						:title="$t('dialogs.disconnectAgreementsItem.title')"
						:value="value"
						submit-btn-color="blue"
						submit-btn-dark
						@cancel="$emit('update:value', false)"
						@submit="handleDisconnect">
	</frp-confirm-dialog>
</template>

<script>
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import { RouteNames } from "@/router/bar/routes";
import { createNamespacedHelpers } from "vuex";
import colorsMixin from "Mixins/colorsMixin";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpConfirmDialog from "@/components/dialogs/FrpConfirmDialog.vue";
import { actionTypes, mutationTypes, namespace } from "@/store/bar/modules/bankAccount/types";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: {
			type: Boolean,
			default: false
		},
		bankAccountId: {
			type: String,
			default: ""
		},
		agreementId: {
			type: String,
			default: ""
		},
		agreementIndex: {
			type: Number,
			default: 0
		}
		
	},
	data() {
		return {
			RouteNames,
			namespace
		};
	},
	computed: {
		...mapState({
			isDisconnectingAccountAgreementsItemInProgress: state => state.editableItem.isDisconnectingAccountAgreementsItemInProgress
		}),
	},
	methods: {
		...mapActions({
			disconnect: actionTypes.disconnectAgreementsItem
		}),
		async handleDisconnect() {
			await this.disconnect({ bankAccountId: this.bankAccountId, agreementId: this.agreementId, agreementIndex: this.agreementIndex });
			this.$emit("update:value", false);
		}
	},
	components: {
		FrpConfirmDialog,
		FrpDialog,
		FrpBtn
	}
};
</script>
