<template>
	<bar-content-layout v-if="isInitialized">
		<v-form ref="bankAccountForm"
				v-model="bankAccountFormValid">
			<v-row>
				<v-col cols="6">
					<bar-bank-account-information/>
				</v-col>
				<v-col cols="6">
					<v-row class="d-flex flex-column">
						<v-col class="pb-1">
							<bar-bank-account-agreements/>
						</v-col>
						<v-col class="pb-1">
							<bar-bank-account-applications/>
						</v-col>
						<v-col
							v-if="can([Permissions.BAR_ACCOUNT_CONTROL_READ, Permissions.BAR_ACCOUNT_RESPONSIBLE_READ, Permissions.BAR_ADMINISTRATOR_READ])">
							<bar-bank-account-control/>
						</v-col>
					</v-row>
				</v-col>
			</v-row>
			
			<frp-confirm-dialog v-if="isBankAccountUnsavedChangesDialogEnabled"
								submit-btn-color="blue"
								submit-btn-dark
								@submit="onCancelFormSubmit"
								@cancel="isBankAccountUnsavedChangesDialogEnabled = false"
								:title="$t('dialogs.confirmOperationWithUnsavedChanges.title')"
								:description="$t('dialogs.confirmOperationWithUnsavedChanges.description')">
			</frp-confirm-dialog>
			
			<frp-confirm-dialog v-if="isDeleteBankAccountDialogEnabled"
								submit-btn-color="blue"
								submit-btn-dark
								@submit="handleDeleteBankAccount"
								@cancel="isDeleteBankAccountDialogEnabled = false"
								:title="$t('dialogs.confirmDeleteBankAccount.title')"
								:description="$t('dialogs.confirmDeleteBankAccount.description', {bankAccountNumber: accountNumber})"
								:loading="isBankAccountDeleting"
								:submit-btn="$t('dialogs.confirmDeleteBankAccount.submitBtn')">
			</frp-confirm-dialog>
		</v-form>
		
		<frp-dialog v-model="isSaveNewAccountAgreementsDialogEnabled" :title="$t('dialogs.addNewAccountAgreements.title')">
			<template #content="{ onIntersect }">
				<div v-intersect="onIntersect" class="primary--text text-body-2">
					<div class="d-flex flex-column">
						<span class="mb-1"
							  v-for="(item, index) in newAccountAgreements" :key="`${index}-${item?.name}`">
							<span v-if="index === newAccountAgreements.length - 1">
								{{ `${item?.name}` }} 
							</span>
							<span v-else>
								{{ `${item?.name},` }} 
							</span>
						</span>
					</div>
					
					<div class="mt-2">
						<span>
							{{ $t("dialogs.addNewAccountAgreements.text") }}
						</span>
					</div>
				</div>
			</template>
			
			<template #footer>
				<frp-btn outlined
						 color="primary"
						 @click="isSaveNewAccountAgreementsDialogEnabled = false">
					{{ $t("buttons.cancel") }}
				</frp-btn>
				<frp-btn elevation="0"
						 color="blue"
						 dark
						 @click="handleSaveNewAccountAgreementsAndUpdateBankAccount">
					{{ $t("buttons.confirm") }}
				</frp-btn>
			</template>
		</frp-dialog>
		
		<template #footer-actions>
			<frp-btn v-if="isDeleteBankAccountEnabled"
					 class="px-9"
					 elevation="0"
					 dark
					 @click="isDeleteBankAccountDialogEnabled = true"
					 color="secondary">
				{{ $t("buttons.deleteBankAccount") }}
			</frp-btn>
			
			<v-fade-transition>
				<frp-btn v-show="isStateContainsUnsavedChanges()"
						 elevation="0"
						 dark
						 color="blue"
						 @click="isBankAccountUnsavedChangesDialogEnabled = true"
						 :disabled="!isStateContainsUnsavedChanges()">
					{{ $t("buttons.cancel") }}
				</frp-btn>
			</v-fade-transition>
			
			<frp-btn v-if="can(Permissions.BAR_ACCOUNT_UPDATE)"
					 :disabled="isSaveBankAccountDisabled"
					 :loading="isBankAccountSaving"
					 class="px-9"
					 elevation="0"
					 dark
					 @click="handleUpdateBankAccount"
					 color="blue">
				{{ $t("buttons.save") }}
			</frp-btn>
		</template>
	</bar-content-layout>
	<bar-bank-account-details-loader v-else/>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpConfirmDialog from "@/components/dialogs/FrpConfirmDialog.vue";
import FrpDialog from "@/components/dialogs/FrpDialog.vue";
import BarContentLayout from "@/components/layouts/BarContentLayout.vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import formMixin from "@/mixins/formMixin";
import { listMixin } from "@/mixins/listMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { i18n } from "@/plugins";
import { RouteNames } from "@/router/bar/routes";
import { namespace } from "@/store/bar/modules/bankAccount";
import { actionTypes, getterTypes, mutationTypes } from "@/store/bar/modules/bankAccount/types";
import { getterTypes as barUserModuleGetterTypes } from "@/store/bar/modules/user/types";
import storeManager from "@/store/manager";
import BarBankAccountDetailsLoader from "@/views/bar/bankAccount/BarBankAccountDetailsLoader.vue";
import BarBankAccountAgreements from "@/views/bar/bankAccount/sections/agreements/BarBankAccountAgreements.vue";
import BarBankAccountApplications from "@/views/bar/bankAccount/sections/applications/BarBankAccountApplications.vue";
import BarBankAccountControl from "@/views/bar/bankAccount/sections/control/BarBankAccountControl.vue";
import BarBankAccountInformation from "@/views/bar/bankAccount/sections/information/BarBankAccountInformation.vue";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);
const barUserModuleHelpers = createNamespacedHelpers(storeManager.bar.barUser.namespace);

export default {
	metaInfo: {
		title: i18n.t("metaTitles.bankAccount")
	},
	mixins: [storeModuleBasedPage, formMixin, listMixin, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			RouteNames,
			isBankAccountUnsavedChangesDialogEnabled: false,
			isSaveNewAccountAgreementsDialogEnabled: false,
			isDeleteBankAccountDialogEnabled: false
		};
	},
	computed: {
		...mapGetters({
			isStateContainsUnsavedChanges: getterTypes.stateContainsUnsavedChanges
		}),
		...barUserModuleHelpers.mapGetters({
			isBarUserAdministrator: barUserModuleGetterTypes.isBarUserAdministrator,
			isBarUserProjectFinancialControlDepartmentManager: barUserModuleGetterTypes.isBarUserProjectFinancialControlDepartmentManager
		}),
		...mapState({
			isInitialized: state => state.isInitialized,
			accountAgreements: state => state.editableItem.accountAgreements,
			accountApplications: state => state.applications,
			accountNumber: state => state.editableItem.account.accountNumber,
			isBankAccountFormValid: state => state.isBankAccountFormValid,
			isBankAccountNumberUniqueCheckInProgress: state => state.isBankAccountNumberUniqueCheckInProgress,
			isBankAccountSaving: state => state.isBankAccountSaving,
			isBankAccountDeleting: state => state.isBankAccountDeleting
		}),
		bankAccountFormValid: {
			get() {
				return this.isBankAccountFormValid;
			},
			set(value) {
				this.setIsBankAccountFormValid(value);
			}
		},
		isSaveBankAccountDisabled() {
			const isSomeNewAccountAgreementNumberNotUnique = this.accountAgreements.some(x => !x.isNumberUnique && x.isNewItem);
			const isSomeNewAccountAgreementNumberUniqueCheckedInProgress = this.accountAgreements.some(x => x.isNumberUniqueCheckInProgress && x.isNewItem);
			
			return !this.isStateContainsUnsavedChanges()
				|| !this.bankAccountFormValid
				|| this.isBankAccountNumberUniqueCheckInProgress
				|| isSomeNewAccountAgreementNumberUniqueCheckedInProgress
				|| isSomeNewAccountAgreementNumberNotUnique;
		},
		isDeleteBankAccountEnabled() {
			return this.isBarUserAdministrator && this.accountApplications.length === 0 && this.accountAgreements.length === 1;
		},
		newAccountAgreements() {
			return this.accountAgreements.filter(x => x.isNewItem).map(x => x.suggestedProjects.find(y => x.number === y.loanApplicationNumber));
		}
	},
	methods: {
		...mapActions({
			initializeStore: actionTypes.initialize,
			updateBankAccount: actionTypes.updateBankAccount,
			deleteBankAccount: actionTypes.deleteBankAccount,
			rollback: actionTypes.cancelChanges
		}),
		...mapMutations({
			setIsBankAccountNumberUnique: mutationTypes.SET_IS_BANK_ACCOUNT_NUMBER_UNIQUE,
			setIsBankAccountFormValid: mutationTypes.SET_IS_BANK_ACCOUNT_FORM_VALID
		}),
		async onCancelFormSubmit(key) {
			await this.rollback(key);
			
			this.isBankAccountUnsavedChangesDialogEnabled = false;
			
			this.$nextTick(() => {
				if(!this.isResetValidationDisabled)
					this.$refs.bankAccountForm.resetValidation();
			});
			this.setIsBankAccountNumberUnique(true);
		},
		async handleUpdateBankAccount() {
			if(this.accountAgreements.some(x => x.isNewItem))
				this.isSaveNewAccountAgreementsDialogEnabled = true;
			else
				await this.updateBankAccount();
		},
		async handleDeleteBankAccount() {
			await this.deleteBankAccount();
		},
		async handleSaveNewAccountAgreementsAndUpdateBankAccount() {
			this.isSaveNewAccountAgreementsDialogEnabled = false;
			await this.updateBankAccount();
		}
	},
	created() {
		this.initializeStore();
	},
	components: {
		FrpDialog,
		FrpConfirmDialog,
		BarBankAccountAgreements,
		BarBankAccountDetailsLoader,
		BarBankAccountControl,
		BarBankAccountApplications,
		FrpBtn,
		BarBankAccountInformation,
		BarContentLayout
	}
};
</script>

<style scoped>

</style>
