<template>
	<bar-content-layout v-if="isInitialized">
		<v-row>
			<v-col cols="6">
				<bar-bank-account-application-information-editable type="update"
																   :trigger-account-usage-processing.sync="triggerAccountUsageProcessing"/>
			</v-col>
		</v-row>
		
		<template #footer-actions>
			<frp-bar-bank-account-application-delete-button/>
			<frp-btn elevation="0"
					 outlined
					 light
					 :disabled="isBankAccountApplicationSaving"
					 class="frp-btn--transparent px-8"
					 :to="{ name: RouteNames.BANK_ACCOUNT_APPLICATION, query: { loanApplicationNumber: $route.query.loanApplicationNumber, sourceType: $route.query.sourceType }, params: { id: $route.params.id } }"
					 color="white">
				{{ $t("buttons.cancel") }}
			</frp-btn>
			<frp-btn v-if="can(Permissions.BAR_APPLICATION_UPDATE)"
					 :disabled="!isStateContainsUnsavedChanges() || !isBankAccountApplicationBankFormValid || !isBankAccountApplicationAccountsFormValid || isRecordUniqueCheckInProgress"
					 :loading="isBankAccountApplicationSaving"
					 class="px-8"
					 elevation="0"
					 dark
					 @click="triggerAccountUsageProcessing = true"
					 color="blue">
				{{ $t("buttons.save") }}
			</frp-btn>
		</template>
	</bar-content-layout>
	<bar-bank-account-application-create-or-update-loader v-else/>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import BarContentLayout from "@/components/layouts/BarContentLayout.vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { i18n } from "@/plugins";
import router from "@/router/bar";
import { RouteNames } from "@/router/bar/routes";
import { getterTypes } from "@/store/bar/modules/bankAccount/types";
import { namespace } from "@/store/bar/modules/bankAccountApplication";
import { actionTypes } from "@/store/bar/modules/bankAccountApplication/types";
import { formatDate } from "@/utils/dates";
import { dateFormat } from "@/utils/formats";
import BarBankAccountApplicationCreateOrUpdateLoader
	from "@/views/bar/bankAccountApplication/BarBankAccountApplicationCreateOrUpdateLoader.vue";
import FrpBarBankAccountApplicationDeleteButton
	from "@/views/bar/bankAccountApplication/components/FrpBarBankAccountApplicationDeleteButton.vue";
import BarBankAccountApplicationInformationEditable
	from "@/views/bar/bankAccountApplication/sections/applicationInformation/BarBankAccountApplicationInformationEditable.vue";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	metaInfo() {
		return {
			title: `${i18n.t("metaTitles.updateBankAccountApplication",
				{ applicationNumber: this.applicationNumber, createdAt: this.createdAt })}`
		};
	},
	mixins: [storeModuleBasedPage, colorsMixin, authorizationMixin],
	data() {
		return {
			namespace,
			RouteNames,
			triggerAccountUsageProcessing: false
		};
	},
	computed: {
		...mapGetters({
			isStateContainsUnsavedChanges: getterTypes.stateContainsUnsavedChanges
		}),
		...mapState({
			isInitialized: state => state.isInitialized,
			editableItem: state => state.editableItem,
			isRecordUniqueCheckInProgress: state => state.form.isRecordUniqueCheckInProgress,
			isBankAccountApplicationSaving: state => state.isBankAccountApplicationSaving,
			isBankAccountApplicationBankFormValid: state => state.isBankAccountApplicationBankFormValid,
			isBankAccountApplicationAccountsFormValid: state => state.isBankAccountApplicationAccountsFormValid
		}),
		applicationNumber() {
			return this.editableItem.applicationNumber || 0;
		},
		createdAt() {
			return formatDate(this.editableItem.createdAt || Date.now(), dateFormat);
		}
	},
	methods: {
		...mapActions({
			initializeStore: actionTypes.initialize,
		}),
		...mapMutations({})
	},
	created() {
		this.initializeStore();
	},
	components: { FrpBarBankAccountApplicationDeleteButton, BarBankAccountApplicationCreateOrUpdateLoader, FrpBtn, BarBankAccountApplicationInformationEditable, BarContentLayout }
};
</script>

<style scoped>

</style>
