import baseMixinTypes from "@/store/shared/base/types";
import stateManipulationMixinTypes from "@/store/shared/stateManipulation/types";

export const namespace = "organization-structure";

export const getterTypes = {
};

export const actionTypes = {
	...baseMixinTypes.actionTypes,
	...stateManipulationMixinTypes.actionTypes,
	fetch: "fetch",
	fetchAvatar: "fetchAvatar",
};

export const mutationTypes = {
	...stateManipulationMixinTypes.mutationTypes,
	...baseMixinTypes.mutationTypes,
	SET_IS_LOADING: "SET_IS_LOADING",
	SET_ITEMS: "SET_ITEMS",
	SET_EMPLOYEE_PHOTO: "SET_EMPLOYEE_PHOTO",
	SET_OPENED_SUBSTRUCTURE_PARENT_DEPARTMENT_ID: "SET_OPENED_SUBSTRUCTURE_PARENT_DEPARTMENT_ID",
};

const hrVacationApplicationTypes = {
	namespace, actionTypes, mutationTypes, getterTypes
};

export default hrVacationApplicationTypes;
