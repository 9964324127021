
<template>
	<div class="fill-height">
		<frp-avatar ref="defaultAvatar" v-show="false" size="30"></frp-avatar>
		
		<div :id="id" class="hr-organization-structure-diagram" @wheel.prevent="onChangeScale" style="width: 100%; height: 100%;"></div>
	</div>
</template>

<script>
import FrpRouterLinkBtn from "@/components/buttons/FrpRouterLinkBtn.vue";
import FrpAvatar from "@/components/common/FrpAvatar.vue";
import KpiContentLayout from "@/components/layouts/KpiContentLayout.vue";
import FrpSpinnerLoader from "@/components/loaders/common/FrpSpinnerLoader.vue";
import router from "@/router/hr";
import { RouteNames } from "@/router/hr/routes";
import BatchService from "@/services/batchService";
import HrUpdateVacationDialog from "@/views/hr/vacationPlans/dialogs/HrUpdateVacationDialog.vue";
import { flatMapDeep } from "lodash";
import * as primitives from "basicprimitives";
import("basicprimitives/css/primitives.css");

const userMenuBatchService = new BatchService({ interval: 300 });

export default {
	data() {
		return {
			control: null,
			currentScale: 0.6,
		};
	},
	props: {
		id: {
			type: String,
			required: true
		},
		items: {
			type: Array,
			required: true
		},
		noSubstructure: Boolean,
		employeesOpened: Boolean,
		employeeOpened: Boolean,
		substructureOpened: Boolean,
		openedEmployeeId: String,
		openedEmployeesDepartmentId: String,
		initialScale: {
			type: Number,
			default: 0.6
		},
	},
	computed: {
		RouteNames() {
			return RouteNames;
		}
	},
	methods: {
		onChangeScale(e) {
			if(!this.control)
				return;
			
			if(e.wheelDeltaY > 0 && this.currentScale <= 1.5) {
				this.currentScale += 0.1;
				this.onScale(this.currentScale);
				return;
			}
			
			if(e.wheelDeltaY < 0 && this.currentScale >= 0.6) {
				this.currentScale -= 0.1;
				this.onScale(this.currentScale);
				return;
			}
		},
		onScale(scale) {
			if (scale != null) {
				this.control.setOption("scale", scale);
			}
			this.control.update(primitives.UpdateMode.Refresh);
		},
		addPhotoToContainer(photoSrc, container) {
			if(photoSrc) {
				const img = document.createElement('img');
				img.alt = 'photo';
				img.src = photoSrc;
				container.appendChild(img);
			} else {
				const defaultPhotoEl = this.$refs.defaultAvatar.$el.cloneNode(true);
				container.appendChild(defaultPhotoEl.children[0]);
			}
		},
		onTemplateRender(event, data) {
			switch (data.renderingMode) {
				case primitives.RenderingMode.Create:
					/* Initialize template content here */
					break;
				case primitives.RenderingMode.Update:
					/* Update template content here */
					break;
			}
			
			// TITLE
			const itemConfig = data.context;
			const itemTitleEl = data.element.querySelector(".org-item-title");
			
			data.element.dataset.id = itemConfig.id;
			itemTitleEl.textContent = itemConfig.title;
			itemTitleEl.title = itemConfig.title;
			
			// HEAD
			const headEl = data.element.querySelector(".org-item-head");
			const headPhoto = data.element.querySelector(".org-item-head .org-item-user-photo");
			const headNameEl = data.element.querySelector(".org-item-head-name");
			
			if(itemConfig.head) {
				headEl.style.opacity = "1";
				
				headNameEl.textContent = itemConfig.head?.name;
				headNameEl.href = router.resolve({ name: RouteNames.EMPLOYEE, params: { id: itemConfig.head.id } }).href;
				data.element.querySelector(".org-item-head-position").textContent = itemConfig.head.position;
				headEl.dataset.userId = itemConfig.head.id;
				headPhoto.textContent = "";
				
				this.addPhotoToContainer(itemConfig.head?.photo, headPhoto);
			} else {
				headEl.style.opacity = "0";
			}
			
			// EMPLOYEES
			
			const employeesEl = data.element.querySelector(".org-item-employees");
			const employeesCountEl = data.element.querySelector(".org-item-employees-count");
			const employeesContainerEl = data.element.querySelector(".org-item-employees-container");
			
			employeesContainerEl.style.opacity = "1";
			
			if(!itemConfig.employees?.length) {
				employeesContainerEl.style.opacity = "0";
				employeesCountEl.style.opacity = "0";
				employeesCountEl.style.cursor = "grab"
			} else {
				employeesCountEl.style.opacity = "1";
				employeesCountEl.style.cursor = "pointer";
				employeesCountEl.textContent = this.$tc("pluralization.employee", itemConfig.employees.length);
				
				employeesEl.textContent = "";
				itemConfig.employees.forEach((x, i) => {
					if(i > 6)
						return;
					
					const container = document.createElement('div');
					const img = document.createElement('img');
					container.classList.add('org-item-employee');
					container.classList.add('org-item-user');
					container.dataset.userId = x.id;
					img.alt = 'photo';
					
					this.addPhotoToContainer(x.photo, container);
					employeesEl.appendChild(container);
				});
			}
			
			// DEPARTMENTS
			if(this.noSubstructure)
				return;
			
			const departmentsCountEl = data.element.querySelector(".org-item-departments-count");
			
			if(itemConfig.level === 3) {
				const departmentsCount = flatMapDeep(itemConfig.departments, (item) => [item, ...(item.departments || [])]).length;
				
				if(departmentsCount) {
					departmentsCountEl.textContent = "Еще " + this.$tc("pluralization.department", departmentsCount);
				} else {
					departmentsCountEl.textContent = "";
				}
			}
			
		},
		getTemplate(level) {
			const result = new primitives.TemplateConfig();
			result.name = `level-${level}`;
			result.itemSize = new primitives.Size(280, 205);
			result.minimizedItemSize = new primitives.Size(3, 3);
			result.highlightPadding = new primitives.Thickness(4, 4, 4, 4);
			
			result.itemTemplate =
				`<div class="org-item org-item-level-${level}">`
					+ '<div class="org-item-title frp-text-overflow-ellipsis"></div>'
					+ '<div class="org-item-content">'
						+ '<div class="org-item-head org-item-user">'
							+ '<div class="d-flex align-center">'
								+ '<div class="org-item-user-photo"></div>'
								+ '<div class="d-flex flex-column">'
									+ '<a target="_blank" class="org-item-head-name frp-text-overflow-ellipsis org-item-link"></a>'
									+ '<span class="org-item-head-position frp-text-overflow-ellipsis"></span>'
								+ '</div>'
							+ '</div>'
						+ '</div>'
						+ '<div class="org-item-employees-container d-flex flex-column">'
							+ `<div class="org-item-employees-title">${this.$t("subheaders.employees")}</div>`
							+ '<div class="org-item-employees d-flex" style="gap: 4px"></div>'
						+ '</div>'
						+ '<div class="org-item-employees-count d-flex justify-center mt-1">'
							+ '<span</span>'
						+ '</div>'
						+ '<div class="org-item-departments-count d-flex justify-center">'
							+ '<span</span>'
						+ '</div>'
					+ '</div>'
				+ '</div>';
			
			return result;
		},
		onResize() {
			this.control.update(primitives.UpdateMode.Refresh);
		},
		async onMousedown(e) {
			const diagram = e.target.closest(".hr-organization-structure-diagram");
			
			if(diagram && diagram.id !== this.id)
				return;
			
			const orgItem = e.target.closest(".org-item");
			const employeeMenu = e.target.closest(".employee-menu");
			const employeesMenu = e.target.closest(".employees-menu");
			
			if(this.employeesOpened && this.openedEmployeesDepartmentId === orgItem?.dataset.id && employeesMenu)
				return;
			
			if(e.target.classList.contains('org-item-employees-count') && e.target.textContent) {
				this.$emit("update:employees-opened", false);
				
				await this.$nextTick(() => this.$emit("update:employees-opened", true, e.clientX, e.clientY, orgItem?.dataset.id));
			} else if(!this.substructureOpened && e.target.classList.contains('org-item-departments-count') && e.target.textContent) {
				this.$emit("update:substructure-opened", true, orgItem?.dataset.id);
			} else {
				if(!employeesMenu)
					this.$emit("update:employees-opened", false);
				
				if(!employeeMenu)
					this.$emit("update:employee-opened", false);
			}
		},
		onMouseover(e) {
			const diagram = e.target.closest(".hr-organization-structure-diagram");
			
			if(diagram && diagram.id !== this.id)
				return;
			
			const element = e.target.closest(".org-item-user, .employee-menu");
			const userId = e.target.closest(".org-item-user[data-user-id], .employee-menu [data-user-id]")?.dataset?.userId;
			
			if(!element) {
				userMenuBatchService.clear();
				
				if(!this.employeeOpened)
					return;
				
				userMenuBatchService.push(() => this.$emit("update:employee-opened", false));
				return;
			}
			
			if(!userId)
				return;
			
			userMenuBatchService.push(() => {
				if(this.employeeOpened && this.openedEmployeeId === userId)
					return;
				
				this.$emit("update:employee-opened", true, e.clientX, e.clientY + 20, element?.dataset.userId);
			});
		},
		initDiagram() {
			this.currentScale = this.initialScale;
			const options = new primitives.OrgConfig();
			
			options.scale = this.currentScale;
			options.items = this.items;
			options.hasSelectorCheckbox = primitives.Enabled.False;
			options.maximumColumnsInMatrix = 1;
			options.lineItemsInterval = 50;
			options.normalItemsInterval = 20;
			options.cousinsIntervalMultiplier = 0;
			options.defaultTemplateName = "level-3";
			options.templates = [this.getTemplate(1), this.getTemplate(2), this.getTemplate(3), this.getTemplate(4), this.getTemplate(5)];
			options.onItemRender = this.onTemplateRender;
			options.pageFitMode = primitives.PageFitMode.None;
			options.alignBranches = false;
			options.navigationMode = primitives.NavigationMode.Inactive;
			
			this.control = primitives.OrgDiagram(document.getElementById(this.id), options);
		}
	},
	async mounted() {
		window.addEventListener("resize", this.onResize);
		document.addEventListener("mousedown", this.onMousedown);
		document.addEventListener("mouseover", this.onMouseover);
		
		await this.$nextTick();
		
		this.initDiagram();
	},
	beforeDestroy() {
		window.removeEventListener("resize", this.onResize);
		document.removeEventListener("mousedown", this.onMousedown);
		document.removeEventListener("mouseover", this.onMouseover);
	},
	components: {
		HrUpdateVacationDialog,
		FrpRouterLinkBtn,
		FrpSpinnerLoader,
		FrpAvatar,
		KpiContentLayout
	}
};
</script>

<style lang="scss">
.hr-organization-structure-diagram {
  cursor: grab !important;
  user-select: none !important;

  .bp-scrollframe {
	border: none;

	&::-webkit-scrollbar {
	  width: 10px;
	  height: 10px;
	}

	&::-webkit-scrollbar-track {
	  background: transparent;
	}

	&::-webkit-scrollbar-thumb {
	  background: rgba(0, 0, 0, 0.1);
	  border-radius: 8px;
	}

	&::-webkit-scrollbar-thumb:hover {
	  background: rgba(0, 0, 0, 0.2);
	}

	&::-webkit-scrollbar-corner {
	  background-color: transparent;
	}
  }

  .orgdiagram {
	user-select: none !important;
  }
  * {
	user-select: none !important;
  }

  &:active {
	cursor: grabbing !important;
  }

  .org-item {
	border: 1px solid;
	border-radius: 8px;

	&.org-item-level-1 {
	  border-color: #c8d7e4;
	  background-color: #f5f9fc;

	  .org-item-title {
		border-color: #b5d5e3;
	  }
	}

	&.org-item-level-2 {
	  border-color: #e8cebd;
	  background-color: #f8efe8;

	  .org-item-title {
		border-color: #efc2a1;
	  }
	}

	&.org-item-level-3 {
	  border-color: #d5e1a6;
	  background-color: #f1f5de;

	  .org-item-title {
		border-color: #c4da86;
	  }
	}

	&.org-item-level-4 {
	  border-color: #b3e1a6;
	  background-color: #e4f4ed;

	  .org-item-title {
		border-color: #8eda86;
	  }
	}

	&.org-item-level-5 {
	  border-color: #d3c8e3;
	  background-color: #faf5ff;

	  .org-item-title {
		border-color: #c2b2da;
	  }
	}
  }

  .org-item-content {
	padding: 16px;
  }

  .org-item-title {
	font-size: 16px;
	line-height: 1.2em;
	padding: 16px;
	border-bottom: 1px solid;
	color: #2067b0;
	font-weight: bold;
  }

  .org-item-head {
	img {
	  max-width: 30px;
	  max-height: 30px;
	  border-radius: 50%;
	  margin-right: 16px;
	}

	&-name {
	  font-size: 14px;
	  color: #000000;
	}

	&-position {
	  font-size: 12px;
	  opacity: 0.6;
	  color: #000000;
	  max-width: 206px;
	}
  }


  .org-item-employees-container {
	min-height: 60px;

	img {
	  max-width: 30px;
	  max-height: 30px;
	  border-radius: 50%;
	}

	.org-item-employees-title {
	  margin: 8px 0 4px;
	  font-size: 12px;
	  font-weight: bold;
	  color: #d4650b;
	  text-transform: uppercase;
	}
  }

  .org-item-departments-count {
	background: #7ea510;
	font-size: 13px;
	color: #ffffff;
	border-radius: 32px;
	cursor: pointer;
  }

  .org-item-employees-count {
	font-size: 12px;
	color: #2067b0;
	font-weight: bold;
	min-height: 20px;
  }

  .org-item-employee {
	height: 30px;
  }

  .org-item-user-photo {
	height: 30px;
	width: 30px;
	margin-right: 8px;
  }
}


.hr-organization-structure-diagram .org-item-link,
.v-menu__content .org-item-link, {
  cursor: pointer;
  text-decoration: none !important;
}

.hr-organization-structure-diagram .org-item-link,
.v-menu__content .org-item-link, {
  &:hover {
	color: #2067b0 !important;
  }
}
</style>
