<template>
	<v-card class="kpi-card pa-6 pb-5">
		<span class="font-weight-bold" style="font-size: 24px">{{ $t("subheaders.approval") }}</span>
		
		<v-divider class="mt-5 mb-2"></v-divider>
		
		<hr-approval v-bind="application.approval"
					 :employee="application.employee"
					 :created-at="application.createdAt"
					 @delete="removeApprover"
					 :deletable-approvers="isHrUserAdministrator"
					 :is-deliting-disabled="isApproverAdding || isApproverRemoving">
		</hr-approval>
		
		<div v-if="isHrUserAdministrator" class="d-flex justify-end">
			<frp-btn
				v-if="[ApiHrVacationApplicationStateEnum.InReview, ApiHrVacationApplicationStateEnum.Approved].includes(application.approval.state)"
				color="blue"
				elevation="0"
				dark
				@click="isWithdrawVacationApplicationDialogOpened = true">
				{{ $t("buttons.withdrawVacationApplication") }}
			</frp-btn>
			<frp-btn @click="isAddApproverDialogOpened = true"
					 :loading="isApproverAdding"
					 :disabled="isApproverRemoving"
					 v-if="application.approval.state === ApiHrVacationApplicationStateEnum.InReview" color="primary"
					 outlined>
				{{ $t("buttons.addApprover") }}
			</frp-btn>
		</div>
		
		<hr-add-approver-dialog v-model="isAddApproverDialogOpened"></hr-add-approver-dialog>
		<hr-withdraw-application-confirm-dialog
			v-model="isWithdrawVacationApplicationDialogOpened"></hr-withdraw-application-confirm-dialog>
	</v-card>
</template>

<script>
import { ApiHrVacationApplicationStateEnum } from "@/api/hr/types/vacationApplication/ApiHrVacationApplicationStateEnum";
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import HrApproval from "@/components/common/HrApproval.vue";
import BodAutocomplete from "@/components/fields/BodAutocomplete.vue";
import FrpAutocomplete from "@/components/fields/FrpAutocomplete.vue";
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import FrpTextField from "@/components/fields/FrpTextField.vue";
import FrpFormActions from "@/components/form/FrpFormActions.vue";
import FrpNestedContentLayout from "@/components/layouts/FrpNestedContentLayout.vue";
import KpiContentLayout from "@/components/layouts/KpiContentLayout.vue";
import FrpFormCard from "@/components/markup/FrpFormCard.vue";
import FrpFormCardBlock from "@/components/markup/FrpFormCardBlock.vue";
import FrpFormCardBlockCol from "@/components/markup/FrpFormCardBlockCol.vue";
import FrpFormCardBlockRow from "@/components/markup/FrpFormCardBlockRow.vue";
import FrpFormCardBlocks from "@/components/markup/FrpFormCardBlocks.vue";
import FrpFormItem from "@/components/markup/FrpFormItem.vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import HrWithdrawApplicationConfirmDialog from "@/views/hr/vacationApplication/dialogs/HrWithdrawApplicationConfirmDialog.vue";
import { actionTypes, namespace } from "Store/hr/modules/vacationApplication/types";
import HrAddApproverDialog from "@/views/hr/vacationApplication/dialogs/HrAddApproverDialog.vue";
import { createNamespacedHelpers } from "vuex";
import { namespace as userNamespace } from "@/store/hr/modules/user";
import userTypes from "@/store/hr/modules/user/types";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);
const { mapGetters: mapUserGetters } = createNamespacedHelpers(userNamespace);

export default {
	mixins: [authorizationMixin],
	data() {
		return {
			namespace,
			ApiHrVacationApplicationStateEnum,
			isAddApproverDialogOpened: false,
			isWithdrawVacationApplicationDialogOpened: false
		};
	},
	computed: {
		...mapState({
			application: state => state.application,
			isApproverAdding: state => state.isApproverAdding,
			isApproverRemoving: state => state.isApproverRemoving,
			isVacationApplicationWithdrawing: state => state.isVacationApplicationWithdrawing
		}),
		...mapUserGetters({
			isHrUserAdministrator: userTypes.getterTypes.isHrUserAdministrator
		})
	},
	methods: {
		...mapActions({
			removeApprover: actionTypes.removeApprover
		})
	},
	components: {
		HrWithdrawApplicationConfirmDialog,
		FrpBtn,
		HrApproval,
		BodAutocomplete,
		FrpAutocomplete,
		KpiContentLayout,
		FrpTextField,
		FrpFormActions,
		FrpFormCardBlockCol,
		FrpFormCardBlocks,
		FrpFormCardBlockRow,
		FrpFormCardBlock,
		FrpFormItem,
		FrpFormCard,
		FrpTextarea,
		FrpNestedContentLayout,
		HrAddApproverDialog
	}
};
</script>
