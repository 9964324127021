import IPageState from "@/store/shared/base/types/pageState";
import ApiApplicationStatus from "@/api/bar/types/dictionaries/apiApplicationStatus";
import ApiBankAccountType from "@/api/bar/types/dictionaries/apiBankAccountType";
import { BankAccountInfo, BankAccountInfoHelper } from "@/store/bar/types/bankAccountInfo";
import FormState from "@/store/shared/form/models/formState";
import { BankBranchInfo } from "@/store/bar/types/bankBranchInfo";
import { BankInfo } from "@/store/bar/types/bankInfo";
import { ApiCurrency } from "@/api/bar/types/dictionaries/apiCurrency";
import { ApiBankResponsibleUser } from "@/api/bar/types/dictionaries/apiBankResponsibleUser";
import { Application } from "@/store/bar/modules/bankAccount/types/application";
import ApiMonitoringSystemConnectionType from "@/api/bar/types/dictionaries/apiMonitoringSystemConnectionType";
import { BankSelectionModeTypeEnum } from "@/store/bar/types/BankSelectionModeTypeEnum";

export default class BankAccountState implements IPageState {
	constructor(
		public form: FormState,
		public snapshot: object,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isDictionariesLoading: boolean = false,
		public isFetchBankAccountInfoLoading: boolean = false,
		public isBankAccountAgreementTypesHistoryLoading: boolean = false,
		public isBankAccountSaving: boolean = false,
		public isBankAccountDeleting: boolean = false,
		public isBankAccountFormValid: boolean = false,
		public isBankAccountHasPayments: boolean = false,
		public isBankAccountNumberUniqueCheckInProgress: boolean = false,
		public isBankAccountNumberUnique: boolean = true,
		public applications: Application[] = [],
		public banks: BankInfo[] = [],
		public bankBranches: BankBranchInfo[] = [],
		public monitoringSystemConnectionTypes: ApiMonitoringSystemConnectionType[] = [],
		public signStatuses: ApiApplicationStatus[] = [],
		public bankAccountTypes: ApiBankAccountType[] = [],
		public applicationStatuses: ApiApplicationStatus[] = [],
		public currencies: ApiCurrency[] = [],
		public bankResponsibleUsers: ApiBankResponsibleUser[] = [],
		public isFetchSuggestedBankAndBankBranchByBikLoading: boolean = false,
		public bik: string = "",
		public correspondentAccount: string = "",
		public bankSelectionMode: BankSelectionModeTypeEnum = BankSelectionModeTypeEnum.BIK,
		public editableItem: BankAccountInfo = BankAccountInfoHelper.getEmpty()
	)
	{
	}
}