<template>
	<div>
		<div v-for="(item, i) in filteredFormattedItems" :key="i" class="mb-4">
			<hr-vacation-plans-plan-title v-bind="item"></hr-vacation-plans-plan-title>
			<v-card elevation="0" class="kpi-card pb-9 px-6 pt-6">
				<hr-vacation-plans-progress-bar v-if="isEditMode"
												:max-days="item.totalVacationDays"
												:days="vacationsDaysCount"
												:year="filter.year"
												:min-date="checkIsNotFirstVersion(item) ? vacationReplacementMinDate : undefined"
												:has-long-vacation="hasLongVacation"
												class="mb-4">
				</hr-vacation-plans-progress-bar>
				
				<v-lazy :options="{ threshold: 0.5 }">
					<frp-calendar
						:value="isEditMode ? newVacations.map(x => [x.startDate, x.endDate]) : item.vacationPlan?.plannedVacations.map(x => [x.startDate, x.endDate])"
						@add:period="handleAddPeriod(item, $event)"
						@click:period="handleOpenUpdateVacationDialog($event)"
						:readonly="!isEditMode"
						:disabled-periods="newVacations.filter(x => checkIsVacationImmutable(x) || x.factDate).map(x => [x.startDate, x.endDate])"
						:color="isEditMode ? 'grey' : 'blue'"
						range
						:min-date="checkIsNotFirstVersion(item) ? vacationReplacementMinDate : undefined"
						:calendar-dates="calendarDates"
						:max-days="item.totalVacationDays"
						multiple
						format="number"
						:year="filter.year">
					</frp-calendar>
				</v-lazy>
				
				<div v-if="isEditMode" class="d-flex justify-end mt-8">
					<frp-btn @click="handleCancel"
							 outlined color="primary" elevation="0">
						{{ $t("buttons.cancel") }}
					</frp-btn>
					<frp-btn :disabled="!isVacationsValid || !isStateContainsUnsavedChanges(VacationPlansSnapshotKeysEnum.NEW_VACATIONS)"
							 :loading="isFormSaving"
							 v-if="item.employee.id === user.id"
							 @click="saveDraft"
							 color="blue" dark elevation="0">
						{{ $t("buttons.saveDraft") }}
					</frp-btn>
					<frp-btn :disabled="!isVacationsValid || isAllVacationsHaveApplications"
							 :loading="isSavingAndApproving"
							 v-if="can(Permissions.HR_VACATIONS_PLAN_UPDATE_APPROVE)"
							 @click="saveAndApproveDraft"
							 color="blue" dark elevation="0">
						{{ $t("buttons.saveAndApprove") }}
					</frp-btn>
				</div>
			</v-card>
		</div>
		
		<hr-update-vacation-dialog v-model="isUpdateVacationDialogOpened" v-if="isUpdateVacationDialogOpened"></hr-update-vacation-dialog>
	</div>
</template>

<script>
import FrpCalendar from "@/components/common/FrpCalendar.vue";
import FrpDateField from "@/components/fields/FrpDateField.vue";
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import HrRangeDateField from "@/components/fields/HrRangeDateField.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import { getVacationsWithDays } from "@/store/hr/helpers/calculateVacationDays";
import { checkIsVacationImmutable } from "@/store/hr/modules/vacationPlans/helpers/CheckIsVacationImmutable";
import { HrVacationPlansPageModeEnum } from "@/store/hr/modules/vacationPlans/types/HrVacationPlansPageModeEnum";
import { VacationPlansSnapshotKeysEnum } from "@/store/hr/modules/vacationPlans/types/VacationPlansSnapshotKeysEnum";
import { HrVacationPlanStateEnum } from "@/types/hr/vacation/HrVacationPlanStateEnum";
import HrVacationPlansPlanTitle from "@/views/hr/vacationPlans/components/HrVacationPlansPlanTitle.vue";
import HrVacationPlansProgressBar from "@/views/hr/vacationPlans/components/HrVacationPlansProgressBar.vue";
import FrpBottomSpace from "Components/layouts/FrpBottomSpace";
import { addDays, startOfDay } from "date-fns";
import { isArray, isEqual } from "lodash";
import { RouteNames } from "Router/hr/routes";
import { Permissions } from "@/constants/permissions";
import { namespace } from "Store/hr/modules/vacationPlans";
import { actionTypes, getterTypes, mutationTypes } from "Store/hr/modules/vacationPlans/types";
import { namespace as userNamespace } from "Store/hr/modules/user/types";
import { createNamespacedHelpers } from "vuex";
import colorsMixin from "Mixins/colorsMixin";
import FrpBtn from "Components/buttons/FrpBtn";
import HrUpdateVacationDialog from "Views/hr/vacationPlans/dialogs/HrUpdateVacationDialog";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);
const { mapState: mapUserState } = createNamespacedHelpers(userNamespace);

export default {
	mixins: [colorsMixin],
	data() {
		return {
			RouteNames,
			checkIsVacationImmutable,
			VacationPlansSnapshotKeysEnum,
			namespace,
			Permissions,
			isUpdateVacationDialogOpened: false
		};
	},
	computed: {
		HrVacationPlanStateEnum() {
			return HrVacationPlanStateEnum
		},
		...mapUserState({
			user: state => state.user
		}),
		...mapState({
			filter: state => state.filter,
			vacationReplacementMinDate: state => state.vacationReplacementMinDate,
			isFormSaving: state => state.form.isSaving,
			isSavingAndApproving: state => state.isSavingAndApproving,
			mode: state => state.mode,
			years: state => state.years,
			calendarDates: state => state.calendarDates,
			editableVacation: state => state.editableVacation,
			newVacations: state => state.newVacations
		}),
		...mapGetters({
			isStateContainsUnsavedChanges: getterTypes.stateContainsUnsavedChanges,
			formattedItems: getterTypes.formattedItems,
			filteredFormattedItems: getterTypes.filteredFormattedItems,
			hasLongVacation: getterTypes.hasLongVacation,
			isVacationDaysEnough: getterTypes.isVacationDaysEnough,
			vacationsDaysCount: getterTypes.vacationsDaysCount,
			currentUserPermissions: getterTypes.currentUserPermissions,
			editableVacationSiblingVacations: getterTypes.editableVacationSiblingVacations,
			editableVacationParentVacation: getterTypes.editableVacationParentVacation,
			isAllVacationsHaveApplications: getterTypes.isAllVacationsHaveApplications
		}),
		isVacationsValid() {
			return this.hasLongVacation && this.isVacationDaysEnough;
		},
		isEditMode() {
			return this.mode === HrVacationPlansPageModeEnum.EDIT;
		},
	},
	methods: {
		addDays,
		startOfDay,
		...mapMutations({
			addNewVacationsItem: mutationTypes.ADD_NEW_VACATIONS_ITEM,
			setEditableVacation: mutationTypes.SET_EDITABLE_VACATION,
			setEditableVacationPreviousVacationId: mutationTypes.SET_EDITABLE_VACATION_PREVIOUS_VACATION_ID,
			setEditableVacationIndex: mutationTypes.SET_EDITABLE_VACATION_INDEX,
			setIsNewVacationCreating: mutationTypes.SET_IS_NEW_VACATION_CREATING
		}),
		...mapActions({
			cancelChanges: actionTypes.cancelChanges,
			saveDraft: actionTypes.saveDraft,
			saveAndApproveDraft: actionTypes.saveAndApproveDraft,
			setReadMode: actionTypes.setReadMode
		}),
		handleAddPeriod(plan, [startDate, endDate]) {
			this.setIsNewVacationCreating(true);
			
			const vacation = getVacationsWithDays([{
				previousVacationId: "",
				startDate: startDate,
				endDate: endDate
			}], this.calendarDates)[0];
			
			this.addNewVacationsItem(vacation);
			
			this.handleOpenUpdateVacationDialog([startDate, endDate], vacation);
		},
		handleOpenUpdateVacationDialog([start, end], item = null) {
			if(!item) {
				const idx = this.newVacations.findIndex(x => x.endDate === end && x.startDate === start);
				
				this.setEditableVacationIndex(idx);
				this.setEditableVacation(this.newVacations[idx]);
			} else {
				const idx = this.newVacations.findIndex(x => isEqual(x, item));
				
				this.setEditableVacationIndex(idx);
				this.setEditableVacation(item);
			}
			
			this.isUpdateVacationDialogOpened = true;
		},
		can(value) {
			if(isArray(value))
				return value.some(x => this.currentUserPermissions.includes(x));
			else
				return this.currentUserPermissions.includes(value);
		},
		async handleCancel() {
			await this.cancelChanges(VacationPlansSnapshotKeysEnum.NEW_VACATIONS);
			await this.setReadMode();
		},
		checkIsNotFirstVersion(item) {
			return item.vacationPlan?.previousVacationsPlanId || item.vacationPlan.state === HrVacationPlanStateEnum.APPROVED;
		}
	},
	components: {
		HrRangeDateField,
		HrVacationPlansPlanTitle,
		HrVacationPlansProgressBar,
		FrpIcon,
		FrpCalendar,
		FrpDateField,
		FrpTextarea,
		FrpBottomSpace,
		FrpBtn,
		HrUpdateVacationDialog
	}
};
</script>
