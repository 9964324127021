<template>
	<div class="hr-range-date-field">
		<v-subheader v-if="label" class="pa-0 mb-1 grey--text text-caption" style="height: fit-content">
			<span>{{ label }}</span>
			<span v-if="required">*</span>
		</v-subheader>
		<v-text-field :rules="readonly ? [] : [...rules, ...defaultRules]"
					  :readonly="readonly"
					  :disabled="disabled"
					  :clearable="clearable"
					  :style="{ width }"
					  dense
					  ref="input"
					  :hide-details="hideDetails || (isPickerOpened && !errorState)"
					  :hint="hint"
					  autocomplete="off"
					  v-mask="masks.dateRange"
					  :placeholder="placeholder"
					  @change="onChange"
					  @click="onClick"
					  class="frp-date-field kpi-field"
					  :class="{ 'frp-dense-field': dense }"
					  v-model="internalFormattedRowValue"
					  @update:error="updateErrorState"
					  outlined>
			<template v-slot:append>
				<frp-icon src="ico_calendar" @click.stop="onClick" :color="colors.grey.base"></frp-icon>
			</template>
		</v-text-field>
		<div v-if="isPickerOpened" class="d-flex">
			<v-date-picker v-for="i in 2"
						   :key="getFormattedFirstPickerDate(i)"
						   class="ma-0 pa-0 hr-date-picker frp-calendar"
						   v-model="internalVuetifyFormattedRowDate"
						   full-width
						   @update:picker-date="onUpdatePickerDate(i, $event)"
						   first-day-of-week="1"
						   :show-current="false"
						   :allowed-dates="checkIsAllowed"
						   :max="vuetifyFormattedMaxRowDate"
						   :min="vuetifyFormattedMinRowDate"
						   :picker-date="getFormattedFirstPickerDate(i)"
						   :header-date-format="getHeaderDateFormat"
						   :weekday-format="getWeekdayFormat"
						   no-title
						   range
						   :disabled-periods="disabledPeriods"
						   show-adjacent-months>
			</v-date-picker>
		</div>
	</div>
</template>

<script>
import { ApiCalendarDayTypeEnum } from "@/api/calendar/types/ApiCalendarDayTypeEnum";
import { CalendarDateColorEnum } from "@/types/calendar/CalendarDateColorEnum";
import { convertToTimestamp, findFirstAndLastDates, formatDate } from "@/utils/dates";
import FrpIcon from "Components/icon/FrpIcon";
import { ru } from "date-fns/locale";
import { capitalize } from "lodash";
import colorsMixin from "Mixins/colorsMixin";
import { parseDate } from "Utils/dates";
import {
	isMyDateValidDateFormat,
	isoDateFormat,
	dateFormat,
	isoYearMonthFormat,
	friendlyMonthYearFormat,
	dayOfWeek
} from "Utils/formats";
import {
	format,
	parse,
	isSameDay,
	addMonths,
	addDays,
	subMonths,
	isWithinInterval,
	startOfDay, isWeekend, isSameMonth
} from "date-fns";
import validator from "is-my-date-valid";
import {
	prepareOverlappingPeriodsRules,
	prepareRangeMaxDateRule,
	prepareRangeMaxDaysRule,
	prepareRangeMinDateRule,
	requiredRule
} from "Utils/validation";
import { masks } from "Constants/masks";

const validate = validator({ format: `${isMyDateValidDateFormat}-${isMyDateValidDateFormat}` });

export default {
	mixins: [colorsMixin],
	components: { FrpIcon },
	props: {
		disabled: Boolean,
		placeholder: {
			type: String,
			default: "Введите дату"
		},
		rules: {
			type: Array,
			default: () => []
		},
		disabledPeriods: {
			type: Array,
			default: () => []
		},
		calendarDates: {
			type: Array,
			default: () => []
		},
		maxDays: Number,
		label: String,
		hint: String,
		year: Number,
		readonly: Boolean,
		clearable: Boolean,
		required: Boolean,
		hideDetails: Boolean,
		dense: Boolean,
		value: {
			type: Array
		},
		dialogWidth: {
			type: Number,
			default: 300
		},
		width: {
			default: "100%"
		},
		type: {
			default: "date"
		},
		minDate: {
			default: () => startOfDay(new Date())
		}
	},
	model: {
		prop: "value",
		event: "update:value"
	},
	data() {
		return {
			isoYearMonthFormat,
			isPickerDateUpdating: false,
			isPickerOpened: false,
			errorState: false,
			internalFormattedRowValue: "",
			internalVuetifyFormattedRowDate: [],
			firstPickerDate: 0,
			masks
		};
	},
	computed: {
		defaultRules() {
			if(this.disabled)
				return [];
			
			const rules = [
				prepareRangeMaxDateRule({ getMax: () => this.max, format: dateFormat }),
				prepareRangeMinDateRule({ getMin: () => this.min, format: dateFormat }),
				prepareRangeMaxDaysRule({
					getMaxDays: () => this.maxDays,
					format: dateFormat,
					ignoredDates: this.calendarDates.filter(x => x.type === ApiCalendarDayTypeEnum.Holiday).map(x => x.date)
				})
			];
			if(this.required) rules.push(requiredRule());
			if(this.disabledPeriods.length > 0) rules.push(
				prepareOverlappingPeriodsRules({ format: dateFormat, disabledPeriods: this.disabledPeriods }));
			return rules;
		},
		vuetifyFormattedMinRowDate() {
			if(this.disabled)
				return;
			
			return format(this.min, isoDateFormat);
		},
		vuetifyFormattedMaxRowDate() {
			if(this.disabled)
				return;
			
			return format(this.max, isoDateFormat);
		},
		min() {
			if(this.disabled)
				return;
			
			if(new Date().getFullYear() === this.year)
				return this.minDate;
			else
				return parseDate(`${this.year}-01-01`);
		},
		max() {
			if(this.disabled)
				return;
			
			return parseDate(`${this.year}-12-31`);
		},
		dialogSelectedInternalValue() {
			if(this.internalVuetifyFormattedRowDate && this.internalVuetifyFormattedRowDate.length === 2) {
				return findFirstAndLastDates(this.internalVuetifyFormattedRowDate.map(d => parse(d, isoDateFormat, new Date())));
			} else if(this.internalVuetifyFormattedRowDate.length === 1) {
				return this.internalVuetifyFormattedRowDate.map(d => parse(d, isoDateFormat, new Date()));
			} else {
				return [];
			}
		},
		currentValueFirstMonth() {
			const [startDate] = this.value;
			
			if(!startDate) {
				if(new Date().getFullYear() === this.year)
					return startOfDay(new Date());
				else
					return parseDate(`${this.year}-01-01`);
			}
			
			return startDate;
		},
		holidays() {
			return this.calendarDates.filter(x => x.type === ApiCalendarDayTypeEnum.Holiday);
		}
	},
	methods: {
		addMonths,
		format,
		getFormattedFirstPickerDate(i) {
			return format(addMonths(this.firstPickerDate, i - 1), isoYearMonthFormat);
		},
		getHeaderDateFormat(date) {
			return capitalize(format(parseDate(date, isoYearMonthFormat), friendlyMonthYearFormat, { locale: ru }));
		},
		getWeekdayFormat(date) {
			return format(parseDate(date), dayOfWeek, { locale: ru });
		},
		async onChange(value) {
			if(!value || !validate(value)) {
				this.internalFormattedRowValue = "";
				this.internalVuetifyFormattedRowDate = [];
			}
		},
		async updateValue(value) {
			const isValidValue = value.every(x => typeof x === "number" ? x : x.getTime() - new Date().getTimezoneOffset() * 60 * 1000);
			
			const isNewValue = !isValidValue || value.some(x => !x) || this.value.some(x => !x);
			const isSameDays = value?.every((x, i) => isSameDay(x,
				this.type === "string" ? parseDate(this.value[i]) : this.value[i]));
			
			if(isNewValue || !isSameDays) {
				if(this.type === "date")
					this.$emit("update:value", isValidValue ? value : [null, null]);
				if(this.type === "number")
					this.$emit("update:value", isValidValue ? value.map(x => x.getTime()) : [0, 0]);
				if(this.type === "string")
					this.$emit("update:value", isValidValue ? value.map(x => formatDate(x, isoDateFormat)) : ["", ""]);
				
				if(isValidValue) {
					this.internalVuetifyFormattedRowDate = findFirstAndLastDates(value).map(d => format(d, isoDateFormat));
				} else {
					// временное решение для бага с v-mask
					await this.$nextTick();
					this.internalFormattedRowValue = "";
				}
			}
		},
		async onUpdatePickerDate(idx, date) {
			if(this.isPickerDateUpdating)
				return;
			
			this.isPickerDateUpdating = true;
			
			if(idx === 1)
				this.firstPickerDate = subMonths(this.firstPickerDate, 1);
			else
				this.firstPickerDate = addMonths(this.firstPickerDate, 1);
			
			await this.$nextTick();
			
			this.isPickerDateUpdating = false;
		},
		checkIsAllowed(date) {
			const timestamp = convertToTimestamp(date);
			
			if(this.disabledPeriods.some(([start, end]) => isWithinInterval(timestamp, { start, end })))
				return false;
			
			// Если пользователь в данный момент не выбирает конец периода, то доступны все даты
			if(this.internalVuetifyFormattedRowDate.length !== 1)
				return true;
			
			const currentDate = parse(this.internalVuetifyFormattedRowDate[0], isoDateFormat, new Date()).getTime();
			
			// Если дата меньше первой даты периода, то она недоступна для выбора
			if(timestamp < currentDate)
				return false;
			
			let nextDisabledPeriod;
			this.disabledPeriods.forEach(([start, end]) => {
				const distance = start - currentDate;
				if(currentDate < start && (!nextDisabledPeriod || distance < nextDisabledPeriod[0] - currentDate))
					nextDisabledPeriod = [start, end];
			});
			
			const holidaysCount = this.holidays.filter(x => formatDate(x.date, isoDateFormat) !== date &&
				isWithinInterval(x.date, { start: currentDate, end: timestamp })).length;
			const isLessThanNextDisabledPeriod = !nextDisabledPeriod || timestamp < nextDisabledPeriod[0];
			const isLessThanMaxDays = addDays(currentDate, this.maxDays + holidaysCount) > timestamp;
			
			// Если дата меньше даты начала следующего периода (если он есть)
			// и период с ней не превышает максимальное количество дней для всех периодов (не считая праздники), то дата доступна для выбора
			return isLessThanNextDisabledPeriod && isLessThanMaxDays;
		},
		resetRangeOnBlur(evt) {
			if(!evt.target.classList.contains("v-btn__content") && this.internalVuetifyFormattedRowDate.length) {
				
				if(this.value.some(x => !x)) {
					this.internalVuetifyFormattedRowDate = [];
					
					const sortedDates = findFirstAndLastDates(this.value).map(d => format(d, dateFormat));
					const sortedRowDates = findFirstAndLastDates(this.value).map(d => format(d, isoDateFormat));
					
					this.internalFormattedRowValue = sortedDates.join("-");
					this.internalVuetifyFormattedRowDate = sortedRowDates;
				}
				
				this.internalFormattedRowValue = "";
				this.internalVuetifyFormattedRowDate = [];
				
			}
		},
		closePicker(evt) {
			if(!evt.target.closest(".hr-range-date-field")) {
				this.isPickerOpened = false;
				document.removeEventListener("click", this.closePicker);
			}
		},
		onClick() {
			if(!this.$refs.input.isDisabled || this.isPickerOpened) {
				this.isPickerOpened = !this.isPickerOpened;
				// document.addEventListener("click", this.closePicker);
			}
		},
		updateErrorState(value) {
			this.errorState = value;
		},
		processDates() {
			const dateButtons = document.querySelectorAll(".v-date-picker-table button");
			
			dateButtons.forEach(el => {
				const monthName = el.closest(".v-picker__body").querySelector(".v-date-picker-header__value button").innerHTML
									.split(" ")[0];
				const day = el.querySelector(".v-btn__content").innerHTML;
				const date = parse(`${day} ${monthName}`, "d LLLL", new Date().setFullYear(this.year), { locale: ru });
				
				if(el.disabled)
					return;
				
				if(this.calendarDates?.length) {
					const isDateWeekend = isWeekend(date);
					const calendarDate = this.calendarDates.find(x => isSameDay(date, x.date));
					const color = calendarDate?.color || (isDateWeekend && CalendarDateColorEnum.RED);
					
					if(color)
						el.classList.add(`frp-calendar-date--${color}`);
					
					if(calendarDate?.type === ApiCalendarDayTypeEnum.Holiday)
						el.classList.add(`frp-calendar-date--disabled`);
				}
			});
		}
	},
	mounted() {
		this.onChange(this.internalFormattedRowValue);
	},
	watch: {
		async firstPickerDate(value) {
			if(value) {
				await this.$nextTick();
				this.processDates();
			}
		},
		async isPickerOpened(value) {
			if(value) {
				await this.$nextTick();
				this.processDates();
			}
		},
		disabled(value) {
			if(value)
				this.isPickerOpened = false;
		},
		internalFormattedRowValue(value) {
			if(!value || validate(value)) {
				this.updateValue(value ? findFirstAndLastDates(value.split("-").map(d => parse(d, dateFormat, new Date()))) : [0, 0]);
			}
			
			this.processDates();
		},
		internalVuetifyFormattedRowDate(value) {
			// true, если пользователь выбрал первую дату в date picker'е
			this.$emit("select:firstDate", value?.length === 1);
			
			if(value?.length === 1)
				document.addEventListener("click", this.resetRangeOnBlur);
			else
				document.removeEventListener("click", this.resetRangeOnBlur);
		},
		async dialogSelectedInternalValue(value) {
			if(value?.length === 2) {
				// временное решение для бага с v-mask
				await this.$nextTick();
				this.internalFormattedRowValue = value.map(d => format(d, dateFormat)).join("-");
				this.updateValue(value);
			} else if(value?.length === 1) {
				await this.$nextTick();
				this.internalFormattedRowValue = value.map(d => format(d, dateFormat)).join("-");
			}
		},
		value: {
			handler(newValue, oldValue) {
				const isEndDateVisible = isSameMonth(addMonths(this.firstPickerDate, 1), newValue[1])
					|| isSameMonth(this.firstPickerDate, newValue[1]);
				
				// Месяцы в календарях меняются только при инициализации (oldValue = undefined)
				// или если, например, юзер ввёл значение вручную, а после открыл календарь, где должен открыться введённый месяц
				if(!oldValue || (!!newValue[1] && !isEndDateVisible))
					this.firstPickerDate = this.currentValueFirstMonth;
				
				if(!newValue)
					return;
				
				if(newValue.some(x => !x)) {
					this.internalFormattedRowValue = "";
					this.internalVuetifyFormattedRowDate = [];
				} else {
					const value = this.type === "string" ? newValue.map(x => parseDate(x)) : newValue;
					
					const sortedDates = findFirstAndLastDates(value).map(d => format(d, dateFormat));
					const sortedRowDates = findFirstAndLastDates(value).map(d => format(d, isoDateFormat));
					
					this.internalFormattedRowValue = sortedDates.join("-");
					this.internalVuetifyFormattedRowDate = sortedRowDates;
				}
			},
			immediate: true
		}
	}
};
</script>

<style lang="scss">
.hr-date-picker {
  .v-date-picker-table {
	height: 220px;

	.frp-calendar-date--disabled:not(.v-btn--active) {
	  pointer-events: none;
	}
  }

  .v-date-picker-header {
	height: 36px;
	background-color: var(--v-blue-base);

	.v-date-picker-header__value {
	  pointer-events: none;
	  font-size: 12px;

	  & > div {
		transition: none !important;
	  }
	}

	button, button span {
	  color: var(--v-white-base) !important;

	  &.v-btn--disabled {
		display: none;
	  }
	}
  }

  thead th {
	color: var(--v-grey-darken2) !important;
  }

  .v-btn {
	border-radius: 0;
	height: 20px;

	&::before, &:focus::before {
	  color: var(--v-grey-base);
	  opacity: 0;
	}

	&:hover::before {
	  opacity: 0.08;
	}
  }

  .v-date-picker-table tbody .v-btn.v-btn--active {
	background-color: unset !important;

	&:not(.frp-calendar-date--disabled) {
	  color: var(--v-blue-lighten1) !important;
	}

	&.v-btn--disabled {
	  color: var(--v-grey-darken2) !important;
	}
  }

  tbody td button {
	&:has(.v-date-picker-table__events) {
	  &:not(.v-btn--active) {
		pointer-events: none;
	  }

	  color: var(--v-secondary-lighten2) !important;
	}

	.v-date-picker-table__events {
	  display: none;
	}
  }
}

.hr-date-picker:nth-child(1) {
  border-top-right-radius: 0;

  .v-date-picker-header {
	border-radius: 4px 0 0 4px;

	& > button:last-child {
	  display: none;
	}
  }
}

.hr-date-picker:nth-child(2) {
  border-top-left-radius: 0;

  .v-date-picker-header {
	border-radius: 0 4px 4px 0;

	& > button:first-child {
	  display: none;
	}
  }
}
</style>
