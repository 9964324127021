<template>
	<v-dialog :persistent="persistent"
			  :value="value"
			  @input="$emit('update:value', $event)"
			  @keydown.esc="$emit('update:value', false)"
			  :hide-overlay="hideOverlay"
			  content-class="overflow-hidden"
			  :scrollable="scrollable"
			  overlay-opacity="0"
			  width="fit-content"
			  :content-class="contentClass"
			  style="z-index: 290">
		<template v-slot:activator="{ on, attrs }">
			<slot name="activator" :on="on" :attrs="attrs"></slot>
		</template>
		
		<v-card style="background: rgba(0, 0, 0, 0.5)" class="d-flex align-start flex-wrap overflow-hidden" elevation="0">
			<v-container fluid class="overflow-y-auto frp-scrollbar" style="width: fit-content">
				<v-row no-gutters class="d-flex" style="position: sticky; top: 0; z-index: 100">
					<v-col>
						<div class="d-flex justify-end">
							<v-icon dark class="close-btn pointer mdi-18px" @click="$emit('update:value', false)">
								mdi-close
							</v-icon>
						</div>
					</v-col>
				</v-row>
				
				<v-row class="my-0">
					<v-col class="py-0">
						<slot name="content"></slot>
					</v-col>
				</v-row>
			</v-container>
		</v-card>
	</v-dialog>
</template>

<script>

import FrpBtn from "@/components/buttons/FrpBtn.vue";
import colorsMixin from "@/mixins/colorsMixin";

export default {
	components: { FrpBtn },
	mixins: [colorsMixin],
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: Boolean,
		hideOverlay: Boolean,
		title: String,
		contentClass: String,
		titleCenter: {
			type: Boolean,
			default: false
		},
		persistent: Boolean,
		scrollable: {
			type: Boolean,
			default: false
		}
	}
};
</script>

<style lang="scss" scoped>
.close-btn {
  &::after {
	opacity: 0 !important;
  }
}
</style>
