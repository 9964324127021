import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import urls, { urlTemplateParts } from "@/api/config/urls";
import { prepareUrl } from "@/utils/prepareUrlQuery";
import { ApiHrGetVacationApplicationsParameters } from "@/api/hr/types/vacationApplication/apiHrGetVacationApplicationsParameters";
import { ApiHrVacationApplication } from "@/api/hr/types/vacationApplication/apiHrVacationApplication";
import {
	ApiHrCreateOwnExpenseVacationApplicationRequest
} from "@/api/hr/types/vacationApplication/apiHrCreateOwnExpenseVacationApplicationRequest";
import {
	ApiHrCreatePlannedVacationApplicationRequest
} from "@/api/hr/types/vacationApplication/apiHrCreatePlannedVacationApplicationRequest";
import { ApiHrCreateOtherVacationApplicationRequest } from "@/api/hr/types/vacationApplication/apiHrCreateOtherVacationApplicationRequest";
import { ApiHrGetSubstitutesParameters } from "@/api/hr/types/vacationApplication/apiHrGetSubstitutesParameters";
import { ApiHrVacationApplicationSubstitute } from "@/api/hr/types/vacationApplication/apiHrVacationApplicationSubstitute";
import { ApiHrAvailableVacation } from "@/api/hr/types/vacationApplication/apiHrAvailableVacation";
import { ApiHrCreateStudyVacationApplicationRequest } from "@/api/hr/types/vacationApplication/apiHrCreateStudyVacationApplicationRequest";
import { ApiHrCreateDonorVacationApplicationRequest } from "@/api/hr/types/vacationApplication/apiHrCreateDonorVacationApplicationRequest";
import {
	ApiHrCreateClinicalExaminationVacationApplicationRequest
} from "@/api/hr/types/vacationApplication/apiHrCreateClinicalExaminationVacationApplicationRequest";
import {
	ApiHrCreateUnplannedVacationApplicationRequest
} from "@/api/hr/types/vacationApplication/apiHrCreateUnplannedVacationApplicationRequest";

export class HrVacationApplicationController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}

	getApplications = async (parameters: ApiHrGetVacationApplicationsParameters = {}) => {
		return await this.client.get<ApiHrVacationApplication[]>(prepareUrl(urls.hr.vacationApplication.getApplications, parameters));
	};

	getApplication = async (id: string) => {
		return await this.client.get<ApiHrVacationApplication>(urls.hr.vacationApplication.getApplication.replace(urlTemplateParts.id, id));
	};

	createOwnExpenseVacationApplication = async (payload: ApiHrCreateOwnExpenseVacationApplicationRequest) => {
		return await this.client.post<ApiHrVacationApplication>(prepareUrl(urls.hr.vacationApplication.createOwnExpenseVacationApplication),
			payload);
	};

	createPlannedVacationApplication = async (payload: ApiHrCreatePlannedVacationApplicationRequest) => {
		return await this.client.post<ApiHrVacationApplication>(prepareUrl(urls.hr.vacationApplication.createPlannedVacationApplication),
			payload);
	};

	createUnplannedVacationApplication = async (payload: ApiHrCreateUnplannedVacationApplicationRequest) => {
		return await this.client.post<ApiHrVacationApplication>(prepareUrl(urls.hr.vacationApplication.createUnplannedVacationApplication),
			payload);
	};
	
	getVacationApplicationsPermissions = async (year: string) => {
		return await this.client.get<string[]>(urls.hr.vacationApplication.getPermissions.replace(urlTemplateParts.id, year), {});
	};
	
	createStudyVacationApplication = async (payload: ApiHrCreateStudyVacationApplicationRequest) => {
		return await this.client.post<ApiHrVacationApplication>(prepareUrl(urls.hr.vacationApplication.createStudyVacationApplication),
			payload);
	};
	
	createDonorVacationApplication = async (payload: ApiHrCreateDonorVacationApplicationRequest) => {
		return await this.client.post<ApiHrVacationApplication>(prepareUrl(urls.hr.vacationApplication.createDonorVacationApplication),
			payload);
	};
	
	createClinicalExaminationVacationApplication = async (payload: ApiHrCreateClinicalExaminationVacationApplicationRequest) => {
		return await this.client.post<ApiHrVacationApplication>(prepareUrl(urls.hr.vacationApplication.createClinicalExaminationVacationApplication),
			payload);
	};

	createOtherVacationApplication = async (payload: ApiHrCreateOtherVacationApplicationRequest) => {
		return await this.client.post<ApiHrVacationApplication>(prepareUrl(urls.hr.vacationApplication.createOtherVacationApplication),
			payload);
	};

	sendToReview = async (applicationId: string) => {
		return await this.client.post<void>(prepareUrl(urls.hr.vacationApplication.sendToReview.replace(urlTemplateParts.id,
			applicationId)), {});
	};

	withdraw = async (applicationId: string) => {
		return await this.client.post<ApiHrVacationApplication>(prepareUrl(urls.hr.vacationApplication.withdraw.replace(urlTemplateParts.id, applicationId)),
			{});
	};

	addApprover = async (applicationId: string, approverId: string) => {
		return await this.client.post<ApiHrVacationApplication>(prepareUrl(urls.hr.vacationApplication.addApprover
														   .replace(urlTemplateParts.id, applicationId)
														   .replace(urlTemplateParts.subId, approverId)), {});
	};

	removeApprover = async (applicationId: string, approverId: string) => {
		return await this.client.delete<ApiHrVacationApplication>(prepareUrl(urls.hr.vacationApplication.removeApprover
													   .replace(urlTemplateParts.id, applicationId)
													   .replace(urlTemplateParts.subId, approverId)));
	};

	getSubstitutes = async (employeeId: string, parameters: ApiHrGetSubstitutesParameters) => {
		return await this.client.get<ApiHrVacationApplicationSubstitute[]>(prepareUrl(urls.hr.substitute.getSubstitutes.replace(
			urlTemplateParts.id,
			employeeId), parameters));
	};

	getAvailableVacations = async (employeeId: string) => {
		return await this.client.get<ApiHrAvailableVacation[]>(prepareUrl(urls.hr.vacation.getAvailableVacations.replace(
			urlTemplateParts.id,
			employeeId)));
	};


	// Для реестра задач
	signDocument = async (documentId: string, signature: string) => {
		return await this.client.post<void>(urls.hr.vacationApplication.signDocument.replace(urlTemplateParts.id, documentId),
			{ signature });
	};

	approve = async (applicationId: string, approverId: string) => {
		return await this.client.post<void>(prepareUrl(urls.hr.vacationApplication.approve
														   .replace(urlTemplateParts.id, applicationId)
														   .replace(urlTemplateParts.id, approverId)), {});
	};

	decline = async (applicationId: string, approverId: string, reason: string) => {
		return await this.client.post<void>(prepareUrl(urls.hr.vacationApplication.decline
														   .replace(urlTemplateParts.id, applicationId)
														   .replace(urlTemplateParts.id, approverId)), { reason });
	};
}
