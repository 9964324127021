import { actionTypes, getterTypes, mutationTypes, namespace } from "@/store/bar/modules/bankAccountApplication/types";
import {
	mutationTypes as documentsMutationTypes,
	namespace as documentsNamespace
} from "@/store/bar/modules/bankAccountApplication/modules/documents/types";
import AbortService from "@/services/abortService";
import { BarController } from "@/api/bar";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import BaseMixinBuilder from "@/store/shared/base";
import { ActionTree, createNamespacedHelpers, GetterTree, MutationTree } from "vuex";
import AlertHelper from "@/store/modules/alerts/helpers/alertHelper";
import { DictionariesController } from "@/api/bar/dictionaries";
import BankAccountApplicationState from "@/store/bar/modules/bankAccountApplication/types/bankAccountApplicationState";
import FormMixinBuilder from "@/store/shared/form";
import SnapshotMixinBuilder from "@/store/shared/snapshot";
import SnapshotOptions from "@/store/shared/snapshot/snapshotOptions";
import stateSnapshotKeys from "@/store/shared/snapshot/keys";
import { cloneDeep, find, first, groupBy } from "lodash";
import { BankAccountApplicationInfoHelper } from "@/store/bar/types/bankAccountApplicationInfo";
import { BankInfo, BankInfoMapper } from "@/store/bar/types/bankInfo";
import { BankAccountData, BankAccountDataHelper } from "@/store/bar/types/bankAccountData";
import { v4 as generateGuid } from "uuid";
import router from "@/router/bar";
import { RouteNames } from "@/router/bar/routes";
import alertService, { AlertKeys } from "@/store/modules/alerts/services/alertService";
import { BankAccountTypeEnum } from "@/store/bar/types/bankAccountTypeEnum";
import { i18n } from "@/plugins";
import { StatusHistoryItemMapper } from "@/store/bar/types/statusHistoryItem";
import { resolveAction, resolveGetter, resolveMutation, resolveNestedState } from "@/utils/vuexModules";
import bankAccountDocumentsModule from "@/store/bar/modules/bankAccountApplication/modules/documents";
import bankAccountLegalDepartmentModule from "@/store/bar/modules/bankAccountApplication/modules/legalDepartment";
import bankAccountDocumentsTypes from "@/store/bar/modules/bankAccountApplication/modules/documents/types";
import bankAccountApplicationLegalDepartmentTypes from "@/store/bar/modules/bankAccountApplication/modules/legalDepartment/types";
import bankAccountApplicationMessengerModule from "@/store/bar/modules/bankAccountApplication/modules/messenger";
import bankAccountApplicationMessengerTypes from "@/store/bar/modules/bankAccountApplication/modules/messenger/types";
import storeManager from "@/store/manager";
import BankAccountApplicationDocumentsState
	from "@/store/bar/modules/bankAccountApplication/modules/documents/types/bankAccountApplicationDocumentsState";
import BankAccountApplicationLegalDepartmentState
	from "@/store/bar/modules/bankAccountApplication/modules/legalDepartment/types/bankAccountApplicationLegalDepartmentState";
import BankAccountApplicationMessengerState
	from "@/store/bar/modules/bankAccountApplication/modules/messenger/types/bankAccountApplicationMessengerState";
import { SourceTypeEnum } from "@/store/bar/types/SourceTypeEnum";
import { ApiBankAccountApplicationAccount } from "@/api/bar/types/apiBankAccountApplicationAccount";
import { BankAccountApplicationStatusTypeEnum } from "@/store/bar/types/BankAccountApplicationStatusTypeEnum";
import { convertIsoToNumber } from "@/utils/dates";
import { ApiGetBankAccountApplicationsParameters } from "@/api/bar/types/apiGetBankAccountApplicationsParameters";
import {
	ApiChangeBankAccountApplicationInfoRequestMapper
} from "@/api/bar/types/bankAccountApplicationInfo/apiChangeBankAccountApplicationInfoRequest";
import HttpNotFoundException from "@/exceptions/httpNotFoundException";
import { ApiBankInfoPersisted } from "@/api/bar/types/dictionaries/apiBankInfoPersisted";
import { maxLengths } from "@/utils/validation";
import { BankAccountApplicationButtonTypeEnum } from "@/store/bar/types/BankAccountApplicationButtonTypeEnum";
import { ApiSignerEnum } from "@/api/bar/types/ApiSignerEnum";
import { saveAs } from "file-saver";
import contentDispositionParser from "content-disposition-parser";
import userTypes from "@/store/bar/modules/user/types";
import rootTypes from "@/store/types";
import { PageModeType } from "@/store/types/pageModeType";
import AccessForbiddenException from "@/exceptions/accessForbiddenException";
import { filterAccountUsagesByAccountId } from "@/store/bar/helpers/filterAccountUsagesByAccountId";
import { buttonUnlockConditions } from "@/store/bar/helpers/buttonUnlockConditions";
import { ApiExistingApplication } from "@/api/bar/types/apiExistingApplicationsPersisted";

const abortService = new AbortService();
const barController = new BarController(abortService);
const dictionariesController = new DictionariesController(abortService);

const formMixin = (new FormMixinBuilder()).build();
const snapshotMixin = (new SnapshotMixinBuilder({
	options: [
		new SnapshotOptions({
			key: stateSnapshotKeys.LAST_SAVED,
			fields: ["editableItem"]
		})
	]
})).build();

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new BankAccountApplicationState(
			formMixin.state(),
			snapshotMixin.state()
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<BankAccountApplicationState, any>>{
	...formMixin.getters,
	...snapshotMixin.getters,
	[getterTypes.getBankName]: state => (bankId: string) => {
		return state.banks.find(x => x.id === bankId)?.name;
	},
	[getterTypes.lawyerStaffEmployees]: state => {
		return state.staffEmployees.filter(x => x.isLawyerManager);
	},
	[getterTypes.buttonUnlocked]: (state, getters, rootState, rootGetters) => (buttonType: BankAccountApplicationButtonTypeEnum) => {
		if(rootGetters[resolveGetter(storeManager.bar.barUser.namespace, userTypes.getterTypes.isBarUserAdministrator)]) return true;
		
		const roles: Record<string, boolean> = {
			isBarUserBorrower: rootGetters[resolveGetter(storeManager.bar.barUser.namespace, userTypes.getterTypes.isBarUserBorrower)],
			isBarUserCuratorsDepartmentManager: rootGetters[resolveGetter(storeManager.bar.barUser.namespace,
				userTypes.getterTypes.isBarUserCuratorsDepartmentManager)],
			isBarUserProjectFinancialControlDepartmentManager: rootGetters[resolveGetter(storeManager.bar.barUser.namespace,
				userTypes.getterTypes.isBarUserProjectFinancialControlDepartmentManager)],
			isBarUserLawyerManager: rootGetters[resolveGetter(storeManager.bar.barUser.namespace,
				userTypes.getterTypes.isBarUserLawyerManager)]
		};
		
		const status = state.editableItem.status;
		
		const applicableStatuses = Object.keys(roles).reduce((statuses, role) => {
			if(roles[role] && buttonUnlockConditions[buttonType][role]) {
				statuses.push(...buttonUnlockConditions[buttonType][role]);
			}
			
			return statuses;
		}, [] as (BankAccountApplicationButtonTypeEnum | string)[]);
		
		if(applicableStatuses.length) {
			return applicableStatuses.includes(status);
		} else {
			return buttonUnlockConditions[buttonType]?.default?.includes(status);
		}
	}
};

const actions = <ActionTree<BankAccountApplicationState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...formMixin.actions,
	...snapshotMixin.actions,
	async [actionTypes.initialize]({ rootState, dispatch, state, commit }) {
		await dispatch(actionTypes.initializeBase);
		
		const { loanApplicationNumber, sourceType } = rootState.route.query;
		const id = rootState.route.params.id;
		
		if(id) {
			const res = await dispatch(actionTypes.tryFetchBankAccountApplication);
			
			if(!res)
				return;
			
			await dispatch(actionTypes.fetchAccountUsagesById);
			
			if(rootState.route.name !== RouteNames.BANK_ACCOUNT_APPLICATION_UPDATE)
				await dispatch(actionTypes.fetchBankAccountApplicationAccounts);
		} else {
			if(Object.values(SourceTypeEnum).every(x => x !== sourceType)) {
				commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.PAGE_NOT_FOUND, { root: true });
				return;
			}
			
			// Если source - LK, то мы запрашиваем все заявки со статусом "В работе", сортируем по дате и делаем редирект на первую заявку
			if(sourceType === SourceTypeEnum.LK) {
				const res = await dispatch(actionTypes.tryFetchLKApplications, { loanApplicationNumber });
				
				if(!res)
					return;
				
				if(state.LKApplications.length) {
					return await dispatch(actionTypes.redirectToExistedInProgressApplication, { loanApplicationNumber });
				}
			}
			
			const res = await dispatch(actionTypes.tryFetchAgreementInfo, loanApplicationNumber);
			
			if(!res)
				return;
			
			await dispatch(actionTypes.fetchDictionaries);
			
			commit(mutationTypes.SET_EDITABLE_ITEM_SOURCE, sourceType);
			commit(mutationTypes.SET_EDITABLE_ITEM_LOAN_APPLICATION_NUMBER, loanApplicationNumber);
			commit(mutationTypes.ADD_EDITABLE_ITEM_ACCOUNTS_ITEM);
		}
		
		commit(mutationTypes.SET_IS_INITIALIZED, true);
		commit(mutationTypes.SET_STATE_SNAPSHOT, stateSnapshotKeys.LAST_SAVED);
		
		if(id && state.editableItem.source === SourceTypeEnum.KZ)
			await dispatch(actionTypes.initializeSubModules);
	},
	async [actionTypes.fetchSuggestedBankAndBankBranchByBik]({ commit, dispatch }, bik) {
		commit(mutationTypes.SET_IS_FETCH_SUGGESTED_BANK_AND_BANK_BRANCH_BY_BIK_LOADING, true);
		
		try {
			const apiBankInfoPersisted: ApiBankInfoPersisted = await dictionariesController.getBankAndBankBranchByBik(bik);
			
			const mappedBankInfo: BankInfo = BankInfoMapper.map(apiBankInfoPersisted);
			
			await dispatch(actionTypes.setSelectedBank, mappedBankInfo);
			
			if(mappedBankInfo.branches.length === 1)
				await dispatch(actionTypes.setSelectedBankBranch, first(mappedBankInfo.branches));
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
			
			await dispatch(actionTypes.setSelectedBank, null);
		} finally {
			commit(mutationTypes.SET_IS_FETCH_SUGGESTED_BANK_AND_BANK_BRANCH_BY_BIK_LOADING, false);
		}
	},
	async [actionTypes.fetchAccountUsagesById]({ commit, dispatch, rootState }, bik) {
		try {
			const accountUsages = await barController.getAccountUsagesById(rootState.route.params.id);
			
			commit(mutationTypes.SET_ACCOUNT_USAGES, accountUsages);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		}
	},
	async [actionTypes.setBik]({ commit, dispatch, state }, bik) {
		commit(mutationTypes.SET_BIK, bik);
		
		if(bik.length !== maxLengths.bik)
			return await dispatch(actionTypes.setSelectedBank, null);
		else
			await dispatch(actionTypes.fetchSuggestedBankAndBankBranchByBik, bik);
	},
	async [actionTypes.initializeSubModules]({ dispatch, rootState, commit }) {
		await dispatch(resolveAction(bankAccountDocumentsModule.namespace, bankAccountDocumentsTypes.actionTypes.initialize), {},
			{ root: true });
		await dispatch(
			resolveAction(bankAccountLegalDepartmentModule.namespace, bankAccountApplicationLegalDepartmentTypes.actionTypes.initialize),
			{}, { root: true });
		await dispatch(
			resolveAction(bankAccountApplicationMessengerModule.namespace, bankAccountApplicationMessengerTypes.actionTypes.initialize), {},
			{ root: true });
	},
	async [actionTypes.fetchDictionaries]({ commit, state }) {
		commit(mutationTypes.SET_IS_DICTIONARIES_LOADING, true);
		
		try {
			const [
				banks,
				signStatuses,
				bankAccountTypes,
				electronicDocumentStatuses,
				paperDocumentStatuses,
				applicationStatuses,
				currencies,
				staffEmployees,
				frpEmployees,
				applicationBankAccountUsageTypes,
				signers,
				bankSettings
			] = await Promise.all([
				dictionariesController.getBanks(),
				dictionariesController.getSignStatuses(),
				dictionariesController.getBankAccountTypes(),
				dictionariesController.getElectronicDocumentStatuses(),
				dictionariesController.getPaperDocumentStatuses(),
				dictionariesController.getApplicationStatuses(),
				dictionariesController.getCurrencies(),
				dictionariesController.getStaffEmployees(),
				dictionariesController.getFrpEmployees(),
				dictionariesController.getApplicationBankAccountUsageTypes(),
				dictionariesController.getSigners(),
				dictionariesController.getBankSettings()
			]);
			
			commit(mutationTypes.SET_BANKS, banks.map(x => BankInfoMapper.map(x)));
			commit(mutationTypes.SET_SIGN_STATUSES, signStatuses);
			commit(mutationTypes.SET_BANK_ACCOUNT_TYPES, bankAccountTypes.filter(x => x.code !== BankAccountTypeEnum.UNKNOWN));
			commit(mutationTypes.SET_ELECTRONIC_DOCUMENT_STATUSES, electronicDocumentStatuses);
			commit(mutationTypes.SET_PAPER_DOCUMENT_STATUSES, paperDocumentStatuses);
			commit(mutationTypes.SET_APPLICATION_STATUSES, applicationStatuses);
			commit(mutationTypes.SET_CURRENCIES, currencies);
			commit(mutationTypes.SET_STAFF_EMPLOYEES, staffEmployees);
			commit(mutationTypes.SET_FRP_EMPLOYEES, frpEmployees);
			commit(mutationTypes.SET_APPLICATION_BANK_ACCOUNT_USAGE_TYPES, applicationBankAccountUsageTypes);
			commit(mutationTypes.SET_SIGNERS, signers);
			commit(mutationTypes.SET_BANK_SETTINGS, bankSettings);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_DICTIONARIES_LOADING, false);
		}
	},
	async [actionTypes.tryFetchLKApplications]({ commit, state }, { loanApplicationNumber }: { loanApplicationNumber: string }) {
		commit(mutationTypes.SET_IS_DICTIONARIES_LOADING, true);
		
		try {
			const parameters = {
				loanApplicationNumber,
				status: BankAccountApplicationStatusTypeEnum.IN_PROGRESS,
				sourceType: SourceTypeEnum.LK
			} as ApiGetBankAccountApplicationsParameters;
			
			const { items } = await barController.getBankAccountApplicationsItems(parameters);
			
			commit(mutationTypes.SET_LK_APPLICATIONS, items);
			
			return true;
		} catch (error) {
			console.error(error);
			
			switch (error.constructor) {
				case HttpNotFoundException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.PAGE_NOT_FOUND, { root: true });
					break;
				case AccessForbiddenException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.ACCESS_DENIED, { root: true });
					break;
				default:
					AlertHelper.handleGeneralRequestErrors(error);
					break;
			}
			
			return false;
		} finally {
			commit(mutationTypes.SET_IS_DICTIONARIES_LOADING, false);
		}
	},
	async [actionTypes.redirectToExistedInProgressApplication]({ commit, state },
		{ loanApplicationNumber }: { loanApplicationNumber: string })
	{
		commit(mutationTypes.SET_IS_DICTIONARIES_LOADING, true);
		
		try {
			const sortedByDateItems = state.LKApplications.sort(
				(a, b) => convertIsoToNumber(b.createdAt) - convertIsoToNumber(a.createdAt));
			const firstItem = first(sortedByDateItems);
			
			await router.push({
				name: RouteNames.BANK_ACCOUNT_APPLICATION,
				params: { id: firstItem!.id },
				query: {
					loanApplicationNumber: loanApplicationNumber,
					sourceType: SourceTypeEnum.LK
				}
			}).catch(() => {
			});
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_DICTIONARIES_LOADING, false);
		}
	},
	async [actionTypes.tryFetchAgreementInfo]({ commit }, projectId) {
		commit(mutationTypes.SET_IS_DICTIONARIES_LOADING, true);
		
		try {
			const agreement = await dictionariesController.getAgreementInfo(projectId);
			
			commit(mutationTypes.SET_EDITABLE_ITEM_AGREEMENT, agreement);
			
			return true;
		} catch (error) {
			console.error(error);
			
			switch (error.constructor) {
				case HttpNotFoundException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.PAGE_NOT_FOUND, { root: true });
					break;
				case AccessForbiddenException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.ACCESS_DENIED, { root: true });
					break;
				default:
					AlertHelper.handleGeneralRequestErrors(error);
					break;
			}
			
			return false;
		} finally {
			commit(mutationTypes.SET_IS_DICTIONARIES_LOADING, false);
		}
	},
	async [actionTypes.tryFetchBankAccountApplication]({ commit, state, rootState, dispatch }) {
		commit(mutationTypes.SET_IS_FETCH_BANK_ACCOUNT_APPLICATION_LOADING, true);
		
		try {
			const bankAccountApplication = await barController.getBankAccountApplication(rootState.route.params.id);
			
			commit(mutationTypes.SET_EDITABLE_ITEM, BankAccountApplicationInfoHelper.map(bankAccountApplication));
			
			await dispatch(actionTypes.fetchDictionaries);
			
			await Promise.all([
				dispatch(actionTypes.initializeBankAccountApplicationInfo),
				dispatch(actionTypes.fetchBankAccountApplicationAccounts)
			]);
			
			return true;
		} catch (error) {
			console.error(error);
			
			switch (error.constructor) {
				case HttpNotFoundException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.PAGE_NOT_FOUND, { root: true });
					break;
				case AccessForbiddenException:
					commit(rootTypes.mutationTypes.SET_PAGE_MODE, PageModeType.ACCESS_DENIED, { root: true });
					break;
				default:
					AlertHelper.handleGeneralRequestErrors(error);
					break;
			}
			
			return false;
		} finally {
			commit(mutationTypes.SET_IS_FETCH_BANK_ACCOUNT_APPLICATION_LOADING, false);
		}
	},
	async [actionTypes.updateStatusToReturnToDSP]({ commit, state, rootState, dispatch }) {
		commit(mutationTypes.SET_IS_RETURNING_TO_DSP, true);
		
		try {
			await barController.updateStatusToReturnToDSP(rootState.route.params.id);
			await dispatch(actionTypes.tryFetchBankAccountApplication);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_RETURNING_TO_DSP, false);
		}
	},
	async [actionTypes.updateStatusToReturnToUFKP]({ commit, state, rootState, dispatch }) {
		commit(mutationTypes.SET_IS_RETURNING_TO_UFKP, true);
		
		try {
			await barController.updateStatusToReturnToUFKP(rootState.route.params.id);
			await dispatch(actionTypes.tryFetchBankAccountApplication);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_RETURNING_TO_UFKP, false);
		}
	},
	async [actionTypes.updateStatusToConnect]({ commit, state, rootState, dispatch }) {
		commit(mutationTypes.SET_IS_STATUS_UPDATING_TO_CONNECT, true);
		
		try {
			await barController.updateStatusToConnect(rootState.route.params.id);
			await dispatch(actionTypes.tryFetchBankAccountApplication);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_STATUS_UPDATING_TO_CONNECT, false);
		}
	},
	async [actionTypes.fetchBankAccountApplicationAccounts]({ commit, state, rootState, dispatch }) {
		commit(mutationTypes.SET_IS_FORM_LOADING, true);
		
		try {
			const accounts: ApiBankAccountApplicationAccount[] = await barController.getBankAccountApplicationAccounts(
				rootState.route.params.id);
			
			const mappedAccounts: BankAccountData[] = state.editableItem.accounts.map((x: BankAccountData) => {
				const account = accounts.find((y: ApiBankAccountApplicationAccount) => y.accountNumber === x.accountNumber);
				if(account && account.accountId)
					return { ...x, accountId: account.accountId };
				
				return x;
			});
			
			commit(mutationTypes.SET_EDITABLE_ITEM_ACCOUNTS, mappedAccounts);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FORM_LOADING, false);
		}
	},
	async [actionTypes.fetchStatusHistoryItems]({ commit, state, rootState, dispatch }) {
		commit(mutationTypes.SET_IS_FETCH_STATUS_HISTORY_LOADING, true);
		
		try {
			const statusHistoryItems = await barController.getBankAccountApplicationStatusHistoryItems(rootState.route.params.id);
			
			commit(mutationTypes.SET_STATUS_HISTORY_ITEMS, statusHistoryItems.map(x => StatusHistoryItemMapper.map(x)));
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_FETCH_STATUS_HISTORY_LOADING, false);
		}
	},
	[actionTypes.initializeBankAccountApplicationInfo]({ commit, state, dispatch }) {
		const selectedBank = state.banks.find(x => x.id === state.editableItem.bankId);
		
		if(!selectedBank) return;
		
		if(state.editableItem.bankBranchId) {
			const selectedBranch = selectedBank.branches.find(x => x.id === state.editableItem.bankBranchId);
			
			// Т.к. бик отсутствует в заявке, сначала нужно найти его,
			// потом по нему отфильтровать филиалы, потому что на один бик их может быть несколько
			if(selectedBank.branches.length)
				commit(mutationTypes.SET_BANK_BRANCHES, selectedBank.branches.filter(x => x.bik === selectedBranch?.bik));
			
			dispatch(actionTypes.setSelectedBankBranch, selectedBranch);
		}
	},
	async [actionTypes.createBankAccountApplication]({ commit, state }) {
		commit(mutationTypes.SET_IS_ACTION_WITH_BANK_ACCOUNT_APPLICATION_LOADING, true);
		
		try {
			const payload = ApiChangeBankAccountApplicationInfoRequestMapper.map(state.editableItem);
			
			const { id, bankAccountApplicationInfo } = await barController.createBankAccountApplication(payload);
			
			await router.push({
				name: RouteNames.BANK_ACCOUNT_APPLICATION,
				params: { id },
				query: {
					loanApplicationNumber: String(bankAccountApplicationInfo.agreement.number),
					sourceType: bankAccountApplicationInfo.source
				}
			}).catch(() => {
			});
			
			alertService.addInfo(AlertKeys.BANK_ACCOUNT_APPLICATION_ITEM_SUCCESS_CREATED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_ACTION_WITH_BANK_ACCOUNT_APPLICATION_LOADING, false);
		}
	},
	async [actionTypes.updateBankAccountApplication]({ commit, state, rootState }) {
		commit(mutationTypes.SET_IS_ACTION_WITH_BANK_ACCOUNT_APPLICATION_LOADING, true);
		
		try {
			const item = ApiChangeBankAccountApplicationInfoRequestMapper.map(state.editableItem);
			await barController.updateBankAccountApplication(state.editableItem.id!, item);
			
			await router.push({
				name: RouteNames.BANK_ACCOUNT_APPLICATION,
				params: router.currentRoute.params,
				query: router.currentRoute.query
			}).catch(() => {
			});
			
			alertService.addInfo(AlertKeys.BANK_ACCOUNT_APPLICATION_ITEM_SUCCESS_UPDATED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_ACTION_WITH_BANK_ACCOUNT_APPLICATION_LOADING, false);
		}
	},
	async [actionTypes.deleteBankAccountApplication]({ commit, state, rootState }) {
		commit(mutationTypes.SET_IS_ACTION_WITH_BANK_ACCOUNT_APPLICATION_LOADING, true);
		
		try {
			await barController.deleteBankAccountApplication(state.editableItem.id!);
			
			await router.push({
				name: RouteNames.BANK_ACCOUNT_APPLICATIONS_LIST
			});
			
			alertService.addInfo(AlertKeys.BANK_ACCOUNT_APPLICATION_ITEM_SUCCESS_DELETED);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_ACTION_WITH_BANK_ACCOUNT_APPLICATION_LOADING, false);
		}
	},
	[actionTypes.setSelectedBank]({ commit, dispatch, state }, bankInfo) {
		if(!bankInfo || !bankInfo.branches.length) {
			commit(mutationTypes.RESET_CORRESPONDENT_ACCOUNT);
			commit(mutationTypes.RESET_BANK_BRANCHES);
			commit(mutationTypes.RESET_EDITABLE_ITEM_BANK_ID);
			return;
		}
		
		commit(mutationTypes.SET_BANK_BRANCHES, bankInfo.branches);
		commit(mutationTypes.SET_EDITABLE_ITEM_BANK_ID, bankInfo.id);
	},
	[actionTypes.setSelectedBankBranch]({ commit, dispatch, state }, bankBranchInfo) {
		if(!bankBranchInfo) {
			commit(mutationTypes.RESET_EDITABLE_ITEM_BANK_BRANCH_ID);
			return;
		}
		
		commit(mutationTypes.SET_BIK, bankBranchInfo.bik);
		commit(mutationTypes.SET_CORRESPONDENT_ACCOUNT, bankBranchInfo.correspondentAccount);
		commit(mutationTypes.SET_EDITABLE_ITEM_BANK_BRANCH_ID, bankBranchInfo.id);
	},
	async [actionTypes.checkIsAccountValid]({ commit, state }, id: string) {
		try {
			commit(mutationTypes.SET_IS_RECORD_UNIQUE_CHECK_IN_PROGRESS, true);
			commit(mutationTypes.SET_EDITABLE_ITEM_ACCOUNTS_ITEM_ACCOUNT_IS_ACCOUNT_VALID_CHECK_IN_PROGRESS, { id, value: true });
			
			const accountNumber = state.editableItem.accounts.find(x => x.id === id)?.accountNumber;
			if(!accountNumber) return;
			
			const { items } = await barController.getBankAccountItems({ accountNumber });
			
			const bankAccount = items.find(x => x.bankAccountInfo.account.accountNumber === accountNumber);
			if(!bankAccount) {
				commit(mutationTypes.SET_EDITABLE_ITEM_ACCOUNTS_ITEM_ACCOUNT_IS_ACCOUNT_VALID, { id, value: true });
				return;
			}
			
			const isSameBranch = state.editableItem.bankBranchId === bankAccount?.bankAccountInfo.bankBranchId;
			if(!isSameBranch) {
				alertService.addCustomError(` ${i18n.t("alerts.errors.bankAccountNumberHasDifferentBranch", { id: accountNumber })}`);
			}
			
			
			commit(mutationTypes.SET_EDITABLE_ITEM_ACCOUNTS_ITEM_ACCOUNT_IS_ACCOUNT_VALID, { id, value: isSameBranch });
		} catch (error) {
			AlertHelper.handleGeneralRequestErrors(error);
			commit(mutationTypes.SET_EDITABLE_ITEM_ACCOUNTS_ITEM_ACCOUNT_IS_ACCOUNT_VALID, { id, value: true });
		} finally {
			commit(mutationTypes.SET_IS_RECORD_UNIQUE_CHECK_IN_PROGRESS, false);
			commit(mutationTypes.SET_EDITABLE_ITEM_ACCOUNTS_ITEM_ACCOUNT_IS_ACCOUNT_VALID_CHECK_IN_PROGRESS, { id, value: false });
		}
	},
	async [actionTypes.destroy]({ rootState, dispatch }) {
		await dispatch(actionTypes.destroyBase);
		
		const { isInitialized: isBarDocumentsInitialized } = resolveNestedState<BankAccountApplicationDocumentsState>(rootState,
			storeManager.bar.bankAccountApplicationDocuments.namespace);
		const { isInitialized: isBarLegalDepartmentInitialized } = resolveNestedState<BankAccountApplicationLegalDepartmentState>(rootState,
			storeManager.bar.bankAccountApplicationLegalDepartment.namespace);
		const { isInitialized: isBarMessengerInitialized } = resolveNestedState<BankAccountApplicationMessengerState>(rootState,
			storeManager.bar.bankAccountApplicationLegalDepartment.namespace);
		
		if(isBarDocumentsInitialized)
			await dispatch(resolveAction(bankAccountDocumentsModule.namespace, bankAccountDocumentsTypes.actionTypes.destroyBase), {},
				{ root: true });
		if(isBarLegalDepartmentInitialized)
			await dispatch(resolveAction(bankAccountLegalDepartmentModule.namespace,
				bankAccountApplicationLegalDepartmentTypes.actionTypes.destroyBase), {}, { root: true });
		if(isBarMessengerInitialized)
			await dispatch(resolveAction(bankAccountApplicationMessengerModule.namespace,
				bankAccountApplicationMessengerTypes.actionTypes.destroyBase), {}, { root: true });
	},
	async [actionTypes.fetchAccountUsages]({ commit, state }, projectId) {
		commit(mutationTypes.SET_IS_ACTION_WITH_BANK_ACCOUNT_APPLICATION_LOADING, true);
		
		try {
			const payload = ApiChangeBankAccountApplicationInfoRequestMapper.map(state.editableItem);
			
			let accountUsages = await barController.getAccountUsage(payload);
			
			commit(mutationTypes.SET_EDITABLE_ITEM_ACCOUNT_USAGES, accountUsages);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_ACTION_WITH_BANK_ACCOUNT_APPLICATION_LOADING, false);
		}
	},
	async [actionTypes.fetchExistingApplications]({ commit, state }, excludedAccountIds: string[] = []) {
		commit(mutationTypes.SET_IS_ACTION_WITH_BANK_ACCOUNT_APPLICATION_LOADING, true);
		
		try {
			let existingApplications: ApiExistingApplication[] = [];
			const editableItem = cloneDeep(state.editableItem);
			editableItem.accounts = editableItem.accounts.filter(x => !excludedAccountIds.includes(x.id));
			
			if(editableItem.accounts.length) {
				const payload = ApiChangeBankAccountApplicationInfoRequestMapper.map(editableItem);
				existingApplications = (await barController.checkExistingApplications(payload)).existingApplications;
			}
			
			commit(mutationTypes.SET_EXISTING_APPLICATIONS, existingApplications);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_ACTION_WITH_BANK_ACCOUNT_APPLICATION_LOADING, false);
		}
	},
	async [actionTypes.downloadBankApplicationTemplate]({ commit, state },
		{ projectId, signer }: { projectId: string, signer: ApiSignerEnum })
	{
		commit(mutationTypes.SET_IS_BANK_APPLICATION_TEMPLATE_DOWNLOADING, true);
		
		try {
			const { data, responseHeaders } = await barController.downloadBankApplicationTemplate(projectId, {
				signer
			});
			
			const { "content-disposition": contentDisposition } = responseHeaders;
			const parsedDisposition = contentDispositionParser(contentDisposition);
			
			saveAs(data, parsedDisposition?.filename ?? `${projectId}.docx`);
		} catch (error) {
			console.error(error);
			AlertHelper.handleGeneralRequestErrors(error);
		} finally {
			commit(mutationTypes.SET_IS_BANK_APPLICATION_TEMPLATE_DOWNLOADING, false);
		}
	}
};

const mutations = <MutationTree<BankAccountApplicationState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...formMixin.mutations,
	...snapshotMixin.mutations,
	[mutationTypes.SET_IS_DICTIONARIES_LOADING](state, value) {
		state.isDictionariesLoading = value;
	},
	[mutationTypes.SET_IS_FETCH_BANK_ACCOUNT_APPLICATION_LOADING](state, value) {
		state.isFetchBankAccountApplicationLoading = value;
	},
	[mutationTypes.SET_IS_ACTION_WITH_BANK_ACCOUNT_APPLICATION_LOADING](state, value) {
		state.isBankAccountApplicationSaving = value;
	},
	[mutationTypes.SET_IS_FETCH_STATUS_HISTORY_LOADING](state, value) {
		state.isFetchStatusHistoryLoading = value;
	},
	[mutationTypes.SET_IS_BANK_ACCOUNT_APPLICATION_BANK_FORM_VALID](state, value) {
		state.isBankAccountApplicationBankFormValid = value;
	},
	[mutationTypes.SET_IS_BANK_ACCOUNT_APPLICATION_BANK_FORM_DISABLED](state, value) {
		state.isBankAccountApplicationBankFormDisabled = value;
	},
	[mutationTypes.SET_IS_BANK_ACCOUNT_APPLICATION_ACCOUNTS_FORM_VALID](state, value) {
		state.isBankAccountApplicationAccountsFormValid = value;
	},
	[mutationTypes.SET_IS_BANK_ACCOUNT_APPLICATION_ACCOUNTS_FORM_DISABLED](state, value) {
		state.isBankAccountApplicationAccountsFormDisabled = value;
	},
	[mutationTypes.SET_SIGN_STATUSES](state, value) {
		state.signStatuses = value;
	},
	[mutationTypes.SET_BANK_ACCOUNT_TYPES](state, value) {
		state.bankAccountTypes = value;
	},
	[mutationTypes.SET_ELECTRONIC_DOCUMENT_STATUSES](state, value) {
		state.electronicDocumentStatuses = value;
	},
	[mutationTypes.SET_PAPER_DOCUMENT_STATUSES](state, value) {
		state.paperDocumentStatuses = value;
	},
	[mutationTypes.SET_APPLICATION_STATUSES](state, value) {
		state.applicationStatuses = value;
	},
	[mutationTypes.SET_CURRENCIES](state, value) {
		state.currencies = value;
	},
	[mutationTypes.SET_STAFF_EMPLOYEES](state, value) {
		state.staffEmployees = value;
	},
	[mutationTypes.SET_FRP_EMPLOYEES](state, value) {
		state.frpEmployees = value;
	},
	[mutationTypes.SET_STATUS_HISTORY_ITEMS](state, value) {
		state.statusHistoryItems = value;
	},
	[mutationTypes.SET_BANKS](state, value) {
		state.banks = value;
	},
	[mutationTypes.SET_BANK_BRANCHES](state, value) {
		state.bankBranches = value;
	},
	[mutationTypes.RESET_BANK_BRANCHES](state) {
		state.bankBranches = [];
	},
	[mutationTypes.SET_LK_APPLICATIONS](state, value) {
		state.LKApplications = value;
	},
	[mutationTypes.RESET_BIK](state) {
		state.bik = "";
	},
	[mutationTypes.SET_BIK](state, value) {
		state.bik = value;
	},
	[mutationTypes.RESET_CORRESPONDENT_ACCOUNT](state) {
		state.correspondentAccount = "";
	},
	[mutationTypes.SET_CORRESPONDENT_ACCOUNT](state, value) {
		state.correspondentAccount = value;
	},
	[mutationTypes.RESET_EDITABLE_ITEM](state) {
		state.editableItem = BankAccountApplicationInfoHelper.getEmpty();
	},
	[mutationTypes.SET_EDITABLE_ITEM](state, value) {
		state.editableItem = cloneDeep(value);
	},
	[mutationTypes.RESET_EDITABLE_ITEM_BANK_ID](state) {
		state.editableItem.bankId = "";
	},
	[mutationTypes.SET_EDITABLE_ITEM_BANK_ID](state, value) {
		state.editableItem.bankId = value;
	},
	[mutationTypes.RESET_EDITABLE_ITEM_BANK_BRANCH_ID](state) {
		state.editableItem.bankBranchId = "";
	},
	[mutationTypes.SET_EDITABLE_ITEM_BANK_BRANCH_ID](state, value) {
		state.editableItem.bankBranchId = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_AGREEMENT](state, value) {
		state.editableItem.agreement = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_SOURCE](state, value) {
		state.editableItem.source = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_LOAN_APPLICATION_NUMBER](state, value) {
		state.editableItem.loanApplicationNumber = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_CONTRACT_NUMBER](state, value) {
		state.editableItem.contract.number = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_CONTRACT_OPENING_DATE](state, value) {
		state.editableItem.contract.openingDate = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_IS_LEGAL_DEPARTMENT_REQUEST_SENT](state, value) {
		state.editableItem.isLegalDepartmentRequestSent = value;
	},
	[mutationTypes.SET_EDITABLE_ITEM_STATUS](state, value) {
		state.editableItem.status = value;
	},
	[mutationTypes.RESET_EDITABLE_ITEM_ACCOUNTS](state) {
		state.editableItem.accounts = [];
	},
	[mutationTypes.SET_EDITABLE_ITEM_ACCOUNTS](state, value) {
		state.editableItem.accounts = value;
	},
	[mutationTypes.ADD_EDITABLE_ITEM_ACCOUNTS_ITEM](state) {
		state.editableItem.accounts.push({ ...BankAccountDataHelper.getEmpty(), id: generateGuid() });
	},
	[mutationTypes.SET_EDITABLE_ITEM_ACCOUNTS_ITEM_ACCOUNT_TYPE](state, { id, value }) {
		state.editableItem.accounts[state.editableItem.accounts.findIndex(x => x.id === id)].accountType = cloneDeep(value);
	},
	[mutationTypes.SET_EDITABLE_ITEM_ACCOUNTS_ITEM_ACCOUNT_NUMBER](state, { id, value }) {
		state.editableItem.accounts[state.editableItem.accounts.findIndex(x => x.id === id)].accountNumber = cloneDeep(value);
	},
	[mutationTypes.SET_EDITABLE_ITEM_ACCOUNTS_ITEM_ACCOUNT_CURRENCY_ID](state, { id, value }) {
		state.editableItem.accounts[state.editableItem.accounts.findIndex(x => x.id === id)].currencyId = cloneDeep(value);
	},
	[mutationTypes.SET_EDITABLE_ITEM_ACCOUNTS_ITEM_ACCOUNT_OPENED_AT](state, { id, value }) {
		state.editableItem.accounts[state.editableItem.accounts.findIndex(x => x.id === id)].openedAt = cloneDeep(value);
	},
	[mutationTypes.SET_EDITABLE_ITEM_ACCOUNTS_ITEM_ACCOUNT_IS_ACCOUNT_VALID_CHECK_IN_PROGRESS](state, { id, value }) {
		state.editableItem.accounts[state.editableItem.accounts.findIndex(x => x.id === id)].isAccountUniqueCheckInProgress =
			cloneDeep(value);
	},
	[mutationTypes.SET_EDITABLE_ITEM_ACCOUNTS_ITEM_ACCOUNT_IS_ACCOUNT_VALID](state, { id, value }) {
		state.editableItem.accounts[state.editableItem.accounts.findIndex(x => x.id === id)].isAccountUnique = cloneDeep(value);
	},
	[mutationTypes.REMOVE_EDITABLE_ITEM_ACCOUNTS_ITEM](state, value) {
		state.editableItem.accounts.splice(state.editableItem.accounts.findIndex(x => x.id === value.id), 1);
	},
	[mutationTypes.SET_IS_FETCH_SUGGESTED_BANK_AND_BANK_BRANCH_BY_BIK_LOADING](state, value) {
		state.isFetchSuggestedBankAndBankBranchByBikLoading = value;
	},
	[mutationTypes.SET_ACCOUNT_USAGES](state, value) {
		state.accountUsages = value;
	},
	[mutationTypes.SET_APPLICATION_BANK_ACCOUNT_USAGE_TYPES](state, value) {
		state.applicationBankAccountUsageTypes = value;
	},
	[mutationTypes.RESET_EDITABLE_ITEM_ACCOUNT_USAGES](state) {
		state.editableItem.accountUsages = [];
	},
	[mutationTypes.SET_EDITABLE_ITEM_ACCOUNT_USAGES](state, value) {
		state.editableItem.accountUsages = value;
	},
	[mutationTypes.SET_IS_RETURNING_TO_DSP](state, value) {
		state.isReturningToDsp = value;
	},
	[mutationTypes.SET_IS_RETURNING_TO_UFKP](state, value) {
		state.isReturningToUfkp = value;
	},
	[mutationTypes.SET_IS_STATUS_UPDATING_TO_CONNECT](state, value) {
		state.isStatusUpdatingToConnect = value;
	},
	[mutationTypes.SET_IS_BANK_APPLICATION_TEMPLATE_DOWNLOADING](state, value) {
		state.isBankApplicationTemplateDownloading = value;
	},
	[mutationTypes.SET_SIGNERS](state, value) {
		state.signers = value;
	},
	[mutationTypes.SET_BANK_SETTINGS](state, value) {
		state.bankSettings = value;
	},
	[mutationTypes.SET_EXISTING_APPLICATIONS](state, value) {
		state.existingApplications = value;
	}
};

const subscribe = (store: any) => {
	const { commit, dispatch } = store;
	
	store.subscribe(async ({ type, payload }: any, state: any) => {
		switch (type) {
			case resolveMutation(documentsNamespace, documentsMutationTypes.DOCUMENT_UPLOADED):
			case resolveMutation(documentsNamespace, documentsMutationTypes.DOCUMENT_STATUS_UPDATED):
			{
				dispatch(resolveAction(namespace, actionTypes.tryFetchBankAccountApplication));
				
				break;
			}
			default:
				break;
		}
	});
};

export {
	namespace, state, getters, actions, mutations, subscribe
};

const bankAccountApplicationModule = {
	namespace, state, getters, actions, mutations, subscribe, namespaced: true
};

export default bankAccountApplicationModule;
