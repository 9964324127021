<template>
	<hr-content-layout container-class="pt-1 pt-lg-2 px-4 px-lg-4" bg-height="112">
		<v-card elevation="0"
				class="kpi-card px-6 py-6 mb-6">
			<div class="d-flex flex-wrap" style="gap: 16px">
				<frp-text-field hide-details
								@keydown.enter="applyFilter"
								style="width: 240px"
								v-model="internalFilterValues.name"
								:placeholder="$t('fields.hrFullName.placeholder')">
				</frp-text-field>
				
				<frp-tree-autocomplete item-text="name"
									   item-value="id"
									   @keydown.enter="applyFilter"
									   @firstclick="fetchDepartments"
									   multiple
									   :loading="isDepartmentsLoading"
									   style="width: 350px"
									   :items="departments"
									   v-model="internalFilterValues.departmentIds"
									   color="blue"
									   :placeholder="$t('fields.hrDepartment.placeholder')"
									   children-key="departments"
									   hide-details>
				</frp-tree-autocomplete>
				
				<v-spacer></v-spacer>
				
				<frp-btn @click="resetFilter"
						 :disabled="isFilterEmpty"
						 color="primary"
						 outlined
						 elevation="0">
					{{ $t("buttons.reset") }}
				</frp-btn>
				
				<frp-btn @click="applyFilter"
						 :disabled="!isFilterChanged"
						 no-margin
						 color="blue"
						 dark
						 elevation="0">
					{{ $t("buttons.apply") }}
				</frp-btn>
			</div>
		</v-card>
		
		<v-card elevation="0"
				class="kpi-card pa-0">
			<v-row>
				<v-col class="py-0">
					<v-data-table :headers="headers"
								  :loading-text="$t('tables.loading')"
								  :loading="isItemsLoading"
								  hide-default-footer
								  item-key="id"
								  :items="formattedItems"
								  :mobile-breakpoint="mobileBreakpoint"
								  :items-per-page="-1"
								  :options="options"
								  class="loan-table bod-table hr-table d-flex flex-column">
						<template #item.fullName="{ item }">
							<frp-router-link-btn :to="{ name: RouteNames.EMPLOYEE, params: { id: item.id } }"
												 :color="colors.blue.base"
												 :underline="false">
								{{ item.fullName }}
							</frp-router-link-btn>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-card>
		
		<frp-bottom-space height="24"></frp-bottom-space>
		
		<template #footer>
			<bod-footer>
				<frp-pagination v-if="!isItemsLoading"
								v-model="page"
								active-color="blue"
								:length="Math.ceil(paging.total / paging.pageSize)">
				</frp-pagination>
			</bod-footer>
		</template>
	</hr-content-layout>
</template>

<script>
import FrpLinkBtn from "Components/buttons/FrpLinkBtn";
import FrpRouterLinkBtn from "Components/buttons/FrpRouterLinkBtn";
import FrpPagination from "Components/common/FrpPagination";
import BodAutocomplete from "Components/fields/BodAutocomplete";
import FrpTreeAutocomplete from "Components/fields/FrpTreeAutocomplete";
import FrpIcon from "Components/icon/FrpIcon";
import BodContentLayout from "Components/layouts/BodContentLayout";
import FrpBottomSpace from "Components/layouts/FrpBottomSpace";
import HrContentLayout from "Components/layouts/HrContentLayout";
import { assign, isEqual } from "lodash";
import authorizationMixin from "Mixins/authorizationMixin";
import { RouteNames } from "Router/hr/routes";
import { namespace } from "Store/hr/modules/employees";
import { actionTypes, getterTypes, mutationTypes } from "Store/hr/modules/employees/types";
import EmployeesFilter from "Store/hr/modules/employees/types/employeesFilter";
import { createNamespacedHelpers } from "vuex";
import { listMixin } from "Mixins/listMixin";
import colorsMixin from "Mixins/colorsMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import FrpAlert from "Components/alerts/FrpAlert";
import FrpAlerts from "Components/alerts/FrpAlerts";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpAutocomplete from "Components/fields/FrpAutocomplete";
import FrpCheckbox from "Components/fields/FrpCheckbox";
import FrpTextField from "Components/fields/FrpTextField";
import BodFooter from "Components/layouts/BodFooter";
import BodMain from "Components/layouts/BodMain";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [listMixin, colorsMixin, storeModuleBasedPage, authorizationMixin],
	metaInfo() {
		return {
			title: this.$t("metaTitles.frpEmployees")
		};
	},
	data() {
		return {
			RouteNames,
			namespace,
			headers: [
				{
					text: this.$t("tables.fullName"),
					value: "fullName",
					class: "text-caption",
					sortable: false,
					width: "20%"
				},
				{
					text: this.$t("tables.position"),
					value: "position",
					class: "text-caption",
					sortable: false,
					width: "20%"
				},
				{
					text: this.$t("tables.department"),
					value: "department",
					class: "text-caption",
					sortable: false,
					width: "25%"
				},
				{
					text: this.$t("tables.innerPhone"),
					value: "innerPhone",
					class: "text-caption",
					sortable: false,
					width: "10%"
				},
				{
					text: this.$t("tables.personalMobilePhone"),
					value: "mobilePhone",
					class: "text-caption",
					sortable: false,
					width: "10%"
				},
				{
					text: this.$t("tables.hrEmail"),
					value: "email",
					class: "text-caption",
					sortable: false,
					width: "15%"
				}
			],
			internalFilterValues: {
				name: "",
				departmentIds: []
			}
		};
	},
	computed: {
		...mapState({
			initialized: state => state.isInitialized,
			isDepartmentsLoading: state => state.isDepartmentsLoading,
			departments: state => state.departments
		}),
		...mapGetters({
			formattedItems: getterTypes.formattedItems,
			isNoData: getterTypes.isListingEmpty
		}),
		filter() {
			return {
				name: this.internalFilterValues.name,
				departmentIds: this.internalFilterValues.departmentIds
			};
		},
		isFilterChanged() {
			return !isEqual(Object.fromEntries(Object.entries(this.internalFilterValues).map(([k, v]) => [k, v === null ? [] : v])),
				assign({}, this.filterValues));
		},
		isFilterEmpty() {
			return isEqual(new EmployeesFilter(), this.filterValues);
		}
	},
	methods: {
		...mapMutations({
			setFilterName: mutationTypes.SET_FILTER_NAME,
			setFilterDepartmentIds: mutationTypes.SET_FILTER_DEPARTMENT_IDS,
			resetFilter: mutationTypes.RESET_FILTER
		}),
		...mapActions({
			fetchDepartments: actionTypes.fetchDepartments
		}),
		setInternalFilterValues() {
			Object.keys(this.internalFilterValues).forEach(key => this.internalFilterValues[key] =
				Array.isArray(this.filterValues[key]) ? [...this.filterValues[key]] : this.filterValues[key]);
		},
		applyFilter() {
			this.setFilterName(this.internalFilterValues.name);
			this.setFilterDepartmentIds(this.internalFilterValues.departmentIds || []);
		}
	},
	created() {
		this.initializeStore();
	},
	components: {
		FrpTreeAutocomplete,
		FrpRouterLinkBtn,
		FrpLinkBtn,
		FrpBottomSpace,
		BodAutocomplete,
		FrpPagination,
		FrpIcon,
		FrpBtn,
		FrpCheckbox,
		FrpTextField,
		FrpAutocomplete,
		BodMain,
		FrpAlerts,
		FrpAlert,
		BodFooter,
		BodContentLayout,
		HrContentLayout
	}
};
</script>
