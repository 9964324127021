<template>
	<div class="px-4">
		<frp-btn
			v-if="buttonStatus(BankAccountApplicationButtonTypeEnum.APPLICATION_DELETE_BUTTON) && can(Permissions.BAR_APPLICATION_DELETE)"
			:disabled="isFetchBankAccountApplicationLoading || isBankAccountApplicationSaving"
			dark
			elevation="0"
			@click="isConfirmBankAccountApplicationOpened = true"
			color="red">
			{{ $t("buttons.deleteApplication") }}
		</frp-btn>
		<frp-confirm-dialog v-if="isConfirmBankAccountApplicationOpened"
							width=""
							@submit="deleteBankAccountApplication"
							@cancel="isConfirmBankAccountApplicationOpened = false"
							:loading="isBankAccountApplicationSaving"
							:submit-btn-color="colors.red.base"
							submit-btn-dark
							:text-justify="false"
							:cancel-btn="$t('buttons.cancel')"
							:submit-btn="$t('buttons.delete')"
							:title="$t('dialogs.confirmDeleteBankAccountApplication.title')"
							:description="description">
		</frp-confirm-dialog>
	</div>
</template>

<script>
import FrpConfirmDialog from "@/components/dialogs/FrpConfirmDialog.vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import colorsMixin from "@/mixins/colorsMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import { namespace } from "@/store/bar/modules/bankAccountApplication";
import { actionTypes, getterTypes } from "@/store/bar/modules/bankAccountApplication/types";
import { BankAccountApplicationButtonTypeEnum } from "@/store/bar/types/BankAccountApplicationButtonTypeEnum";
import FrpBtn from "Components/buttons/FrpBtn";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapGetters, mapMutations, mapActions } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin, authorizationMixin],
	data() {
		return {
			isConfirmBankAccountApplicationOpened: false
		};
	},
	computed: {
		BankAccountApplicationButtonTypeEnum() {
			return BankAccountApplicationButtonTypeEnum;
		},
		...mapState({
			isBankAccountApplicationSaving: state => state.isBankAccountApplicationSaving,
			isFetchBankAccountApplicationLoading: state => state.isFetchBankAccountApplicationLoading,
			editableItem: state => state.editableItem
		}),
		...mapGetters({
			buttonStatus: getterTypes.buttonUnlocked
		}),
		loanApplicationNumber() {
			return this.editableItem.loanApplicationNumber;
		},
		accounts() {
			return this.editableItem.accounts.map(v => v.accountNumber);
		},
		description() {
			const accountCount = this.accounts.length > 1 ? 1 : 0;
			
			const translatedLeftPart = this.$t("dialogs.confirmDeleteBankAccountApplication.description",
				{ loanApplicationNumber: this.loanApplicationNumber });
			
			const translatedRightPart = this.$tc("dialogs.confirmDeleteBankAccountApplication.account", accountCount,
				{ account: this.accounts.join(", №") });
			
			return `${translatedLeftPart} ${translatedRightPart}.`;
		}
	},
	components: { FrpConfirmDialog, FrpBtn },
	methods: {
		...mapActions({
			deleteBankAccountApplication: actionTypes.deleteBankAccountApplication
		})
	}
};
</script>

<style scoped>

</style>
