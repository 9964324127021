import TreeModel from "tree-model";

class Route {
	name: string;
	children: Route[];
	
	constructor(name: string, children: Route[] = []) {
		this.name = name;
		this.children = children;
	}
}

export enum RouteNames {
	ROOT = "ROOT",
	CALLBACK = "CALLBACK",
	SILENT_RENEW = "SILENT_RENEW",
	
	EMPLOYEES = "EMPLOYEES",
	EMPLOYEE = "EMPLOYEE",
	
	VACATION_PLANS = "VACATION_PLANS",
	VACATION_PLANS_ADMINISTRATION_EMPLOYEES = "VACATION_PLANS_ADMINISTRATION_EMPLOYEES",
	VACATION_PLANS_ADMINISTRATION_CALENDAR = "VACATION_PLANS_ADMINISTRATION_CALENDAR",
	
	VACATION_APPLICATIONS = "VACATION_APPLICATIONS",
	VACATION_APPLICATION_CREATE = "VACATION_APPLICATION_CREATE",
	VACATION_APPLICATION = "VACATION_APPLICATION",
	
	ME = "ME",
	
	ERROR_PAGE_NOT_FOUND = "ERROR_PAGE_NOT_FOUND",
	ERROR_ACCESS_DENIED = "ERROR_ACCESS_DENIED",
	ERROR_INTERNAL_SERVER_ERROR = "ERROR_INTERNAL_SERVER_ERROR",
	
	TASKS = "TASKS",
	
	DOC = "DOC",
	SUPPORT = "SUPPORT",
	ORGANIZATION_STRUCTURE = "ORGANIZATION_STRUCTURE",
}


const tree = new TreeModel();

export const findRoute = (routeName: string, route = routesThreeRoot) => {
	return route.first(x => x.model.name === routeName);
};

export const findAllByAncestor = (ancestorRouteName: string) => {
	let searchNode: any = findRoute(ancestorRouteName);
	return routesThreeRoot.all(x => x.getPath().includes(searchNode));
};


export const routesThreeRoot = tree.parse<Route>(
	new Route(RouteNames.ROOT, [
		new Route(RouteNames.CALLBACK),
		new Route(RouteNames.SILENT_RENEW),
		
		new Route(RouteNames.EMPLOYEES),
		new Route(RouteNames.EMPLOYEE),
		
		new Route(RouteNames.VACATION_PLANS, [
			new Route(RouteNames.VACATION_PLANS_ADMINISTRATION_EMPLOYEES),
			new Route(RouteNames.VACATION_PLANS_ADMINISTRATION_CALENDAR)
		]),
		
		new Route(RouteNames.VACATION_APPLICATIONS, [
			new Route(RouteNames.VACATION_APPLICATIONS),
			new Route(RouteNames.VACATION_APPLICATION_CREATE),
			new Route(RouteNames.VACATION_APPLICATION)
		]),
		
		new Route(RouteNames.ERROR_PAGE_NOT_FOUND),
		new Route(RouteNames.ERROR_ACCESS_DENIED),
		new Route(RouteNames.ERROR_INTERNAL_SERVER_ERROR),
		
		new Route(RouteNames.TASKS),
		
		new Route(RouteNames.DOC),
		
		new Route(RouteNames.SUPPORT),
		new Route(RouteNames.ORGANIZATION_STRUCTURE),
	]));
